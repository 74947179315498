// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);


fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.field{
  margin-bottom: $base-spacing;
}

legend {
  font-weight: 400;
  font-size: rem(14);
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  line-height: 1;
  margin: 0 0 $small-spacing;
  font-weight: 700;
  text-transform: uppercase;
  font-size: rem(12);
  letter-spacing: rem(2);

  display: block;
  margin-bottom: $small-spacing / 2;
}

//input{ height: $base-spacing*1.5; }

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

select,
#{$all-text-inputs} {
  appearance: none;
  background-color: $offwhite;
  border: $secondary-border;
  border-radius: $base-border-radius;
  //box-shadow: $form-box-shadow;
  box-sizing: border-box;
  //margin-bottom: $base-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    //box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $secondary-border;
    }
  }

  &::placeholder {
    color: $light-gray;
  }
}



textarea {
  resize: vertical;
  height: em(300);
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;

  padding: $small-spacing;
  font-size: 0.9em;
}

select {
  line-height: 1;
  width: 100%;
  height: $base-spacing * 1.5;

  margin-bottom: $small-spacing;


  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url("/img/svg/down-arrow.png");
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 20px;
}

span.form-required {
  color: $action-color;
}



.g-recaptcha{
  // margin-bottom: $base-spacing;
  margin-top: $base-spacing * 3;

}
