.overlay{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right:0;

  visibility: hidden;
  opacity: 0;

  transition: visibility $base-duration, opacity $base-duration $base-timing;

  &.js-active, &.init {
    visibility: visible;
    opacity: 1;
  }

  &.init { padding-left: 0; }
}
