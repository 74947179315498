nav.subnav-dots {
  display: none; //display: table;

  position: fixed;
  text-align: center;
  //background-color: pink;

  visibility: hidden;
  opacity: 0;
  transition: visibility $base-duration, opacity $base-duration $base-timing;

  &.js-active{
    opacity: 1;
    visibility: visible;
  }

  // &.side{
  //   top: 0; bottom: 0;
  //   right: $base-spacing + .75rem;
  //
  //   height: 100%;
  //   width: .75em;
  // }

  // &.bottom{
  //   left: 0; right: 0;
  //   bottom: $base-spacing;
  //
  //   height: .75rem;
  //   width: 100%;
  // }

  top: 0; bottom: 0;
  right: $base-spacing + .75rem;

  height: 100%;
  width: .75em;

  $dot-size: 8;

  & ul{
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    li{
      position: relative;
      display: inline-block;

      width: rem($dot-size) ;
      height: rem($dot-size) ;
      border-radius: 50%;

      text-indent: -999em;
      margin: 0 0 1rem 1rem;

      transition: all $base-duration $base-timing;
      background-color: rgba(255,255,255,.75);

      cursor: pointer;

      &:hover { background-color: rgba(255,255,255,1); }
      &.js-selected {
        background-color: $black;
        border: rem(2) solid rgba(255,255,255,.75);
        width: rem($dot-size)+rem(2) ;
        height: rem($dot-size)+rem(2) ;
      }
    }
  }


  &.altcolor{
    ul li{
      background-color: rgba(0,0,0,.3);
      &:hover { background-color: rgba(0,0,0,.5); }
      &.js-selected {
        background-color: $black;
        border: rem(2) solid rgba(255,255,255,.75);
      }
    }
  }
}
