article.contact header.page--header .page--header__content{ @include container-small; }
article.contact section{ @include container-small; }

article.contact{
  header.page--header .page--header__content span { @extend h3; }

  .contact--content-wrap{
    @include clearfix;
    position: relative;
    padding-bottom: $base-spacing*2;

    font-size: 1rem;
    @include media($phone) { font-size: 0.9rem; }
  }

  .contact--form-wrap{
    border-bottom: $secondary-border;

    @include media($tablet-landscape) {
      display: inline-block;

      padding-bottom: $base-spacing;
      padding-right: $base-spacing*2;

      border-right: $secondary-border;
      border-bottom: none;

      float: left;
      width: 60%;
      //margin-right: rem(336);
    }
    @include media($desktop) {
      width: 70%;
    }

    span.header,
    span.header-confirm{
      display: inline-block;
      margin-bottom: $small-spacing;

      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: em(2);
    }

    //p { margin-bottom: $base-spacing; }



    div.contact--form-wrap__content{
      padding-top:    $base-spacing;
      padding-bottom: $base-spacing*2;
    }


    div.contact--form form,
    div.contact--form__header--default,
    div.contact--form__header--success,
    div.contact--form__header--fail{
      &.hidden{
        visibility: hidden;
        opacity: 0;
        display: none;
      }
    }

    div.contact--form__header--default,
    div.contact--form__header--success,
    div.contact--form__header--fail{
      margin-bottom: $base-spacing*2;
    }

    .contact--form__header--success {
      //margin-bottom: $base-spacing;

      .header{
        text-align: center;
        color: $confirm-color;
      }

      p { margin-bottom: $base-spacing; }

      div.icon{
        height: 8rem;
        width: 8rem;
        margin: 0 auto;
        //text-align: center;

        svg polygon,
        svg path,
        svg rect{ fill: $confirm-color; }
      }
    }
    .contact--form__header--fail {

      background-color: $warning-color ;
      padding: $base-spacing*0.75 $base-spacing;
      margin-top: $base-spacing*-1;

      .header{
        text-align: center;
        color: white;
      }
      p { margin-bottom: 0; }
    }

    p.form-info{ display: block; }


  }

  .contact--other-wrap{
    padding-top:    $base-spacing*2;
    padding-bottom: $base-spacing*2;

    @include media($tablet-landscape) {
      display: inline-block;

      padding-top:  $base-spacing;
      padding-left: $base-spacing*2;

      float: left;
    }

    .contact--email,
    .contact--phone,
    .contact--address{
      display: block;
      position: relative;

      margin-bottom: $base-spacing*1.5;
      &:last-of-type { margin-bottom: 0;}

      vertical-align: top;


      div.icon{
        position: absolute;
        top:0; left:0;
        display: inline-block;
        color: $black;
        font-weight: 700;
        text-transform: lowercase;
        letter-spacing: em(2);

        width: $base-spacing;
        height: $base-spacing;
        //margin-right: $base-spacing*0.5;
        svg{
          g polygon,
          g path,
          g rect{ fill: $black; }
        }

      }
      div.info{
        display: inline-block;
        margin-left: $base-spacing*1.75;

        a#email,
        a#address{
          // letter-spacing: em(2);
          // text-transform: uppercase;
          font-weight: 500;
          height: $base-spacing;
          text-align: right;
        }
        a#tel-num{
          letter-spacing: em(1);
          text-transform: uppercase;
          font-weight: 500;
          height: $base-spacing;

          text-align: right;
        }
        small#tel-alt{
          display: block;
          right: 0;
          font-size: em(12);

          text-transform: uppercase;
          font-weight: 300;

          //text-align: right;
          padding-right: rem(2);
        }
      }

    }
  }

  .contact--social-wrap{
    text-align: center;
    padding-top:    $base-spacing*2;
    padding-bottom: $base-spacing*2;
    background: $black;
    color: $gold;

    span.header{
      display: inline-block;
      margin-bottom: $small-spacing;

      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: em(2);
    }

    .nav--menu--social{
      display: block;

      & li{
        display: inline-block;
        height: 3rem;
        width: 3rem;
        padding: 0.5rem;

        // border: rem(3) solid white;
        // background-color: white;
      }


      & a.nav--menu--socialicon{
        margin-right: $base-spacing;
        &:last-of-type { margin-right: 0; }


        li svg path,
        li svg circle,
        li svg polygon{
          transition: color $base-duration $base-timing;
        }

        &:hover,
        &:active,
        &.js-active:hover,
        &.js-active,
        &:focus{
          color: $white;

          li svg path,
          li svg circle,
          li svg polygon{ fill: $white; }
        }
      }

    }
  }

 .localload > .localload_overlay{ background-color: rgba(255,255,255,.8); }
}

@include media($tablet-landscape) {
  .zd_email{
    width: calc(50% - #{$base-spacing});
    float: left;
    margin-right: $base-spacing;
  }
  .zd_phone{
    width: 50%;
    float: left;
  }

  .zd_type{
    clear: both;
  }
}
