@import "neat-helpers";

// Change the grid settings
$column: 90px;
$gutter: 0;
$grid-columns: 12;


// Define your breakpoints.

//$max-width: 120rem; //em(1920);
// $max-width: 112.5rem; //em(1800);
$max-width: 90rem; //em(1600);

$big-desktop-v:      $max-width ;
$desktop-v:          75em       ; //1200px
$tablet-landscape-v: 56.5em     ; //904px
$tablet-portrait-v:  47.5em     ; //760px
//$tablet-portrait-v:  37.5em     ; //600px
$phone-v:            37.4375em  ; //599px
$small-v:            23.5em  ; //376

// Sizes listed are minimum size; they all go up EXCEPT phone.
$big-desktop:      new-breakpoint(min-width $big-desktop-v      12); //1600px
$desktop:          new-breakpoint(min-width $desktop-v          12); //1200px
$tablet-landscape: new-breakpoint(min-width $tablet-landscape-v 12); //904px
$tablet-portrait:  new-breakpoint(min-width $tablet-portrait-v  8); //760px
$phone:            new-breakpoint(max-width $phone-v            4); //599px
$small:            new-breakpoint(max-width $small-v            4); //599px


@mixin contain(){
  padding-left:$base-spacing;
  padding-right:$base-spacing;
}

@mixin container-no_padding(){
  max-width: $max-width;
  margin-left: auto;
  margin-right:auto;
}

@mixin container(){
  @include contain;
  @include container-no_padding;
}

@mixin container-small(){
  @include contain;
  max-width: $desktop-v;
  margin-left: auto;
  margin-right:auto;
}
