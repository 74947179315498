/* Error message (selector for validation messages) */
#{$all-text-inputs} {
  &.invalid {

    border: 1px solid $warning-color;
    position: relative;
  }
}

.zd-form .field .input.zd-has-error {
  position: relative;

  &::after {

    display: block;
    position: absolute;
    top: $small-spacing * 0.5;
    right: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;

    font-size: rem(18);
    font-weight: 700;
    text-align: center;
    line-height: 1.25rem;

    content: ' ';

    background: url('/img/icons/alert.svg');
    background-size: 1.5rem 1.5rem;
  }
}

.zd-error-list-wrap {
  overflow: hidden;

  transition-duration: $base-duration;
  transition-timing-function: $base-timing;

  @include media($tablet-portrait) {
    transition-property: height;
    height: 0;
  }
}


.zd-form .zd-error-list {
  position: relative;
  display: block;
  margin-bottom: $small-spacing;
  overflow: hidden;
  border-radius: 0 0 3px 3px;

  .zd-error-msg {
  }

  label {
    position: relative;
    background: $warning-color;
    color: $white;

    font-size: rem(14);
    font-weight: 400;
    line-height: $small-spacing * 2;
    letter-spacing: normal;
    text-transform: none;
    text-indent: -.6rem;

    padding: ($small-spacing / 2) $small-spacing ($small-spacing / 2) ($small-spacing*1.5);
    margin-bottom: 0;
    //margin-top: $base-spacing * -1;

    // height: $small-spacing + $small-spacing * 2;

    &::before {
      content: '\203A\00a0';
    }
  }
}
