// Border
$base-border-color: $base-font-color;
$base-border-thickness: rem(3);
$base-border-radius: 0;

$base-border: $base-border-thickness solid $base-border-color;
$secondary-border: rem(1) solid $light-gray;

hr {
  border-bottom: $secondary-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
