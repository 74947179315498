$product-submenu-width: $base-spacing * 5;

.subnav-product--mobile {
  @extend .subnav-product;
  display: block;
}
.subnav-product--desktop{
  @extend .subnav-product;
  display: none;
}

@include media($tablet-landscape) {
  .subnav-product--mobile {display: none;}
  .subnav-product--desktop{display: block;}
}

.subnav-product{
  position: relative;
  height: 100%;

  //margin-top: $base-spacing*6;
  //background-color: red;

  ul{
    position: relative;
    display: block;//Fallback if Flex fails...
    display: flex;
    justify-content: space-between;

    padding-top: $base-spacing*.5;
    padding-bottom: $base-spacing;
    margin-bottom: $base-spacing*1;

    @extend %border-color;
    border-style: solid;
    border-width: 0 0 rem(1) 0;


    @include media($tablet-landscape) {
      position: absolute;
      left: 0; top:0;

      width: $product-submenu-width;
      display: inline-block;

      text-align: left;

      padding-top: 0;
      padding-left: 0;
      padding-right: $base-spacing*1;
      padding-bottom: 0;

      margin-top: $section-base-padding;
      margin-bottom: $base-spacing*2;

      border-bottom-width:none;
      //border-right-width: rem(1);
      border-width: 0 rem(1) 0 0;
    }

    li{
      @extend %navigation;
      @extend %sku-a;
      //@extend %sku-a--svg;
      outline: none;

      font-weight: 700;
      font-size: rem(10);
      display: inline-block;

      cursor: pointer;
      text-align: center;

      padding-left: $small-spacing;
      padding-right:$small-spacing;
      // width: 32%;
      // &:last-of-type{ margin-bottom: 0; }


      @include media($tablet-landscape) {
        font-size: rem(11);
        display: block;
        width: auto;

        margin-bottom: $base-spacing*2;

        padding-left: 0;
        padding-right:0;
        &:first-of-type{ padding-top: $small-spacing*.25; }
        &:last-of-type{ margin-bottom: 0; }
      }

      span.label{
        &:after{
          margin: $small-spacing*.5 auto 0 auto;

          //margin: $small-spacing auto 0 auto;
          display: block;
          //background-color: white;
          height: rem(2);
          width: 0%;
          content: "";

          transition: width $base-timing $base-duration;
        }
      }
      span.icon{
        display: block;
        margin: 0 auto $small-spacing*.75 auto;

        width: 2rem;
        height: 2rem;

        @include media($tablet-landscape) {
          margin: 0 auto $small-spacing*.75 auto;

          width: 2.25rem;
          height: 2.25rem;
        }
      }

    }

    li:hover{
      // span.label { @extend %sku-a:hover;}
      // span.icon { @extend %sku-a--svg:hover }
    }

    li.js-active{
      span.label {
        @extend %sku-a.js-active;
        border-color: $white;

        &:after{
          width: 50%;
        }
      }
      //span.icon { @extend %sku-a--svg.js-active; }
    }
  }
}

.product_info--sizes {
  display: none;
  @include media($tablet-portrait) { display: block; }


  ul{
    @extend %border-color;

    // width: 8.4rem;
    text-align: center;
    margin: 0 auto;
    padding-top: $base-spacing*.75;

    @include media($tablet-portrait) {
      border-style: solid;
      border-width: rem(1) 0 0 0 ;
      width: 8.4rem;
    }

  }
  li{
    // @extend %navigation;
    @extend %sku-a;
    //@extend %sku-a--svg;

    font-weight: 400;
    font-size: rem(10);
    display: inline-block;
    width: 4rem;


    cursor: pointer;
    text-align: center;

    span.label{
      line-height: 1.2;

      &:after{
        margin: $small-spacing*.5 auto 0 auto;

        //margin: $small-spacing auto 0 auto;
        display: block;
        background-color: white;
        height: rem(2);
        width: 0%;
        content: "";

        transition: width $base-timing $base-duration;
      }
    }
    span.icon{
      display: none;

      margin: 0 auto $small-spacing*.75 auto;

      width: 2.5rem;

      @include media($tablet-landscape) {
        display: block;
      }
    }
    &#toggle-bottle--500 span.icon{ height: rem(40); }//specify to fix ie sizing
    &#toggle-bottle--2L span.icon{ height: 3.45rem; }//specify to fix ie sizing

  }

  // li:hover{
  //
  //   span.label { @extend %sku-a:hover ;}
  //   span.icon { @extend %sku-a--svg:hover ; }
  // }

  li.js-active{
    span.label {
      @extend %sku-a.js-active ;
      border-color: $white;

      &:after{
        width: 50%;
      }
    }
    //span.icon { @extend %sku-a--svg.js-active ; }
  }


}
