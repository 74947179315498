.localload{
  position: relative;
  overflow: hidden;
}

.localload > .localload_overlay{
  position: absolute;
  top:0; bottom:0;
  left:0; right:0;
  z-index: 100;
  visibility: hidden;
  opacity: 0;

  background-color: $white;
  transition: visibility $base-duration, opacity $base-duration $base-timing;

  img{
    @include XYcenter();
    width: 20px;
    height: 20px;
  }
}

// .localload.js-loading > .localload_overlay{
//
//
//   visibility: visible;
//   opacity: 1;
//
//   animation-duration: $base-duration;
//   animation-name: fadeInOut;
//   animation-timing-function: $base-timing;
//   //animation-direction: alternate;
// }


// @keyframes fadeInOut {
//   from {
//     opacity: 0;
//   }
//
//   to {
//     visibility: visible;
//     opacity: 1;
//   }
// }


// .localload_overlay{
//   //transition: visibility $base-duration, opacity $base-duration $base-timing;
//
//   animation-duration: $base-duration;
//   animation-name: fadeInOut;
//   animation-timing-function: $base-timing;
//   animation-direction: alternate;
// }
