@import "_colors";

// article.products header.page--header .page--header__content{ @include container-small; }
// article.products section{ @include container-small; }

article.products{
  header.page--header .page--header__content span { @extend h3; }
}

@each $id, $map in $sections-map {
  $bg-color: map-get($map, bgcolor);
  $text-color: map-get($map, text-color);
  $arrow-color: map-get($map, arrow-color);
  $arrow-hover: map-get($map, arrow-hover);

  @if $id == 'latte' {
    $text-color: $black;
    $bg-color: #f5f4df;
  }

  article.products .product--row--wrap##{$id}--product-row{
    background: $bg-color;
    color: $text-color;

    button.product--row__cta {
      background-color: transparent;
      border: $base-border-thickness solid $text-color;

      color: $text-color;
      border-color: $text-color;
      span.icon svg polygon { fill: $text-color; }
      @include media($tablet-landscape) {
        &:hover,
        &:focus {
          color: $gold;
          span.icon svg polygon { fill: $gold; }
        }
      }
      &:before{ background-color: $text-color; }
    }
  }
}

article.products .product--row--wrap{
  overflow: hidden;

  .product--row--content-wrap{
    @include container-small;
    position: relative;
    z-index: 550;
  }
  .product--row--content{
    @include clearfix;
    position: relative;
    z-index: 500;

    padding-top: $base-spacing*5;
    padding-bottom: $base-spacing*5;

    .product--row--bottle-wrap{
      display: none;
      @include media($tablet-portrait) { display: inline-block; }

      float: left;
      //margin-right: $base-spacing;
      margin-top: $base-spacing*-1;

      .product--row--bottleimage{
        position: relative;
        left: -25%;
        width: $base-spacing*8;
      }
    }
    .product--row--text{
      display: inline-block;
      float: left;
      position: relative;
      z-index: 505;

      width: 100%;
      @include media($tablet-portrait) {
        max-width: 30rem;
        margin-top: $base-spacing*2;
      }



    }

    span.product--row__header{
      @include ms-respond(font-size, 2);
      @include media($tablet-portrait) {
        @include ms-respond(font-size, 3);
      }
      display: inline-block;
      //font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: rem(3);

    }

  }


  .product--row--image-wrap{
    display: block;
    position: absolute;
    bottom:0;
    top: 0;
    left: 0;
    right: 0;

    .product--row--image-container {
      position: relative;
      text-align: right;
      height: 100%; width: 100%;

      opacity: 0.4;
      //@include media($tablet-landscape) { opacity: 1; }

      img{
        width:  auto;
        height: 100%;
        max-width: initial;
      }


    }

  }
}

article.products .product--row--wrap#cold-brew--product-row{
  .product--row--content span.product--row__header span{
    display: block;
    font-size: 0.6em;
  }

  .product--row--image-wrap img{
    position: relative;

    right: -5%;
    bottom: -10%;
  }
}

article.products .product--row--wrap#latte--product-row{
  .product--row--image-wrap img{
    position: relative;
    right: -13%;
    top: 5%;
  }
}
