
// @font-face {
//     font-family: 'Gotham';
//     // src: url('lib/fonts/gotham-webfonts/gotham-black-webfont.ttf');
//     src: url('lib/fonts/gotham-webfonts/gotham-black.ttf');
//     src: url('lib/fonts/gotham-webfonts/gotham-black-webfont.eot?#iefix') format('embedded-opentype'),
//          url('lib/fonts/gotham-webfonts/gotham-black-webfont.woff') format('woff'),
//          url('lib/fonts/gotham-webfonts/gotham-black-webfont.ttf') format('truetype'),
//          url('lib/fonts/gotham-webfonts/gotham-black-webfont.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

//$gotham-light: "Gotham Light", Helvetica, Arial, sans-serif;
// @font-face {
//     font-family: 'Gotham Light';
//     src: url('lib/fonts/gotham-webfonts/gotham-light.ttf');
//     // src: url('lib/fonts/gotham-webfonts/gotham-light-webfont.eot?#iefix') format('embedded-opentype'),
//     src: url('lib/fonts/gotham-webfonts/gotham-light.woff') format('woff'),
//          url('lib/fonts/gotham-webfonts/gotham-light.ttf') format('truetype'),
//          url('lib/fonts/gotham-webfonts/gotham-light.svg') format('svg');
//     font-weight: normal;
//     // font-weight: 100;
//     font-style: normal;
// }

//$gotham-medium: "Gotham Medium", Helvetica, Arial, sans-serif;
// @font-face {
//     font-family: 'Gotham Medium';
//     src: url('lib/fonts/gotham-webfonts/gotham-medium.ttf');
//     src: url('lib/fonts/gotham-webfonts/gotham-medium-webfont.eot?#iefix') format('embedded-opentype'),
//            url('lib/fonts/gotham-webfonts/gotham-medium-webfont.woff') format('woff'),
//            url('lib/fonts/gotham-webfonts/gotham-medium-webfont.ttf') format('truetype'),
//            url('lib/fonts/gotham-webfonts/gotham-medium-webfont.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

//$gotham-bold: "Gotham Bold", Helvetica, Arial, sans-serif;
// @font-face {
//     font-family: 'Gotham Bold';
//     src: url('lib/fonts/gotham-webfonts/gotham-bold.ttf');
//     src: url('lib/fonts/gotham-webfonts/gotham-bold-webfont.eot?#iefix') format('embedded-opentype'),
//            url('lib/fonts/gotham-webfonts/gotham-bold-webfont.woff') format('woff'),
//            url('lib/fonts/gotham-webfonts/gotham-bold-webfont.ttf') format('truetype'),
//            url('lib/fonts/gotham-webfonts/gotham-bold-webfont.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
