article.about header.page--header .page--header__content{ @include container-small; }
article.about section{ @include container-small; }

article.about header.page--header{
  span { @extend h3; }

  // background: url("/img/test.jpg");
  // background-size: 100%;
  // color: $white;
}

article.about header.page--header .page--header__content{
  //padding-top: $base-spacing*6;
}

article.about section{

}
