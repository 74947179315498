$bg: $white;
$color1: rgba(0,0,0,1);
$color2: $action-color;

$speed: $base-duration;
$function: cubic-bezier(.68,.14,.25,.88);

$pattythickness: rem(2);
$pattyspacing: rem(3);
$icon_width: rem(18);

.hamburger{
  position: relative;
  display: block;
  z-index: 100;
  width: $icon_width+rem(2);
  height: $icon_width+rem(2);
  padding-top: $icon_width/2;
  cursor: pointer;
  margin: 0 auto;
  // float: right;
}

.hamburger div{ position: relative;}
.hamburger div:before,
.hamburger div:after { position: absolute; }

.hamburger div,
.hamburger div:before,
.hamburger div:after {
  margin: 0 auto;
  content: "";
  background-color: $color1;
  height: $pattythickness;
  width: $icon_width;
  left:0;
  transform: rotate(0deg);
  visibility:visible;

  transition-property: transform, background-color, visibility, top, bottom;
  transition-duration: $speed;
  transition-timing-function: $function;

  //border-radius: $pattythickness;
}
.hamburger div:before{ bottom: $pattythickness + $pattyspacing; }
.hamburger div:after{ top: $pattythickness + $pattyspacing; }

// .hamburger:hover div:before,{
//   bottom:  $pattythickness + $pattyspacing;
//   margin-bottom: 1px;
// }
// .hamburger:hover div:after{
//   top:  $pattythickness + $pattyspacing;
//   margin-top: 1px;
// }

.hamburger:hover div,
.hamburger:hover div:before,
.hamburger:hover div:after {
  background-color: $color2;
}

span:focus .hamburger div,
span:focus .hamburger div:before,
span:focus .hamburger div:after {
  background-color: $color2;
}

.hamburger:active { padding-top: $icon_width/2 + rem(1) }
.hamburger:active div,
.hamburger:active div:before,
.hamburger:active div:after { width:$icon_width; }

.js-active .hamburger > div,
.js-active:focus .hamburger > div{ visibility:hidden; background-color: rgba(0,0,0,0);}

.js-active .hamburger div:before{
  transform: rotate(225deg);
  bottom:0; z-index: 101;
  visibility:visible;
  margin-bottom:0;
}
.js-active .hamburger div:after{
  transform: rotate(-225deg) ;
  top:0; z-index: 101;
  visibility:visible;

  margin-top:0;
}
