section.product_info.cold-brew{
  &#cb_green-tea{
    .product_info--sub_brand{
      @include media($tablet-landscape) { height: $base-spacing*2.333; }
    }
  }

  .product_info--buycta button.buycta{

    background: $gold;
    .text{
      color: $black;
    }
    svg polygon{
      fill: $black;
    }

    &:before{
      background-color: $white;
    }
    &:hover,
    &:focus {
      color: $black;
      &:before{ width: 100%; }
    }
  }


}

section.subbrand_hero.cold-brew#cold-brew--page-hero{
  div.subbrand_hero--background:before{ display: none;}
  div.subbrand_hero--background svg {
    display: none;
    @include media($tablet-portrait) { display: block;}
  }

  background: $richblack;
  .subbrand_hero--submenu{ background: $richblack;}
  .subbrand_hero--submenu-label{ color: $white;}

  ul.subbrand_hero--submenu{
    li{ @include media($tablet-landscape) { text-align: center; } }
    a{ border-bottom-color: $medium-gray;}

    .subbrand_hero--submenu--flavor_descriptor,
    .subbrand_hero--submenu--flavor_name{
      @include media($tablet-landscape) { text-align: center; }
      text-transform: uppercase;
      letter-spacing: 0.125rem;
    }
  }

  div.subbrand_hero--content{
    @include container-small;
    position: relative;
    span.subbrand_hero--headline{
      margin-bottom: $base-spacing*2;

      @include media($tablet-portrait) {
        width: 50%;
        max-width: 34rem;
      }
      @include media($tablet-landscape) {}
      @include media($desktop) {}
      @include media($big-desktop) {}
    }
    p.subbrand_hero--romance{
      max-width: 100%;
      @include media($tablet-portrait) {
        width: 50%;
        max-width: 34rem;
      }
      @include media($tablet-landscape) {}
      @include media($desktop) {}
      @include media($big-desktop) {}
    }

    .subbrand_hero--image-wrap{
      display: none;

      position: absolute;
      z-index: 502;

      @include media($tablet-portrait) {
        display: block;
        top: $base-spacing*5;
        bottom: $base-spacing*3;
        right: $base-spacing;
        height:100%;

        width: 45%;
        max-width: rem(640);
      }
      @include media($tablet-landscape) {
        //right: 0;
        top: $base-spacing*4;

        width: 48%;
        max-width: rem(640);
      }
      @include media($desktop) {
        top: $base-spacing*2;

      }
      @include media($big-desktop) {

      }
    }
  }


}
