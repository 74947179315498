html {
  box-sizing: border-box;
  //@include media($phone)            { font-size: 14px }
  // @include media($tablet-portrait)  { font-size: 16px }
  // @include media($tablet-landscape) { font-size: 16px }
  // @include media($desktop)          { font-size: 16px }
  // @include media($big-desktop)      { font-size: 16px }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  margin: 0;
}
