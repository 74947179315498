body{
  background-color: $offwhite;

  & .page-wrap {

    background-color: $white;

    // background-color: pink;

  }

  & .page{
    position: relative;
    min-height: 100%;
  }

  & .page > header.top-banner{
  }

  & .page > footer{
    @include contain;
    background-color: $offwhite;

    //margin-top: $base-spacing*3;
    padding-top: $base-spacing*3;
    padding-bottom: $base-spacing*3 + $m-nav-height;
    @include media($tablet-landscape) { padding-bottom: $base-spacing*3; }

    @extend small;
    color: $medium-gray;
    font-weight: 400;
    line-height: 1;

    & .footer--links{
      // @extend small;
      margin: $small-spacing auto 0 auto;
      text-align: center;
      span.separator{
        padding: 0 $small-spacing;
      }
      a{
        @extend a.inverted;
        color: $medium-gray;
        text-decoration: underline;
        display: inline-block;
      }
    }

    & .footer--itoen_logo a{
      display: block;
      margin: 0 auto $base-spacing*1.5 auto;
      width: $base-spacing *2.5;
      height: $base-spacing *2.5;

      @include media($tablet-landscape) {
        width: $base-spacing *3;
        height: $base-spacing *3;
      }

      svg{
        g polygon, g path {
          fill: $gray;
          transition: fill $base-duration $base-timing;
        }
      }

      &:hover svg{
        g#clover polygon, g#clover path { fill: $itoen-green; }
        g#logotype polygon, g#logotype path { fill: $black; }
      }
    }

    & .footer--colophon{
      text-align: center;

      span.footer--copyright{
        display: block;
        margin-bottom: $small-spacing;
      }
      span.footer--trademark{
        line-height: 1.5em;
        @include media($tablet-landscape) {
          br.opt{display: none}
        }
      }
    }
  }
}

main.page-content{
  & .container{ @include container; }
  & .container--small{ @include container-small; }
}
