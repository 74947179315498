@each $class, $map in $flavors-map {
  section.subbrand_hero ul.subbrand_hero--submenu a##{$class}--thumbnail{
    $bg-color: map-get($map, bgcolor);
    $text-color: map-get($map, label-text);
    $text-color--darker: darken(map-get($map, label-text), 8%);

    li{
      color: $text-color;
      transition: color $base-duration $base-timing;
      &:hover,
      &:focus {
        color: $gold;
      }
    }
  }
}


section.subbrand_hero .subbrand_hero--submenu-wrap{
  display: block;
  margin: 0 auto;
  padding: $base-spacing 0;

  @include media($tablet-landscape) {
    max-width: $max-width;
    padding: $base-spacing*2 $base-spacing 0 $base-spacing;
  }
}

section.subbrand_hero .subbrand_hero--submenu-label{
  display: none;
  @include media($tablet-landscape) {  display: block; }
  font-size: rem(12);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: rem(2);

  text-align: center;

}

section.subbrand_hero ul.subbrand_hero--submenu{
  @include clearfix;

  overflow: hidden;
  padding: rem(1);
  margin: 0 $base-spacing;

  position: relative;
  z-index: 600;
  text-align: center;

  @include paper-background();
  border-top: $secondary-border;

  @include media($tablet-portrait) { text-align: left; }
  @include media($tablet-landscape) {
    display: block;
    height: inherit;
    margin: inherit;
    padding-top: $base-spacing*2;
    text-align: center;

    background: none;
    border-top: none;
  }

  a {
    display: inline-block;
    position: relative;
    vertical-align: top;
    border-bottom: $secondary-border;

    width: 100%;
    // max-width: ($tablet-portrait-v / 2);

    padding: $base-spacing $base-spacing 0 $base-spacing;
    &:nth-child(odd) { margin-right: $base-spacing; }
    &:last-of-type{ margin-bottom: $base-spacing; }

    @include media($tablet-portrait) {
      float: left;
      width: calc(50% - #{$base-spacing/2});
      //&:nth-child(odd) { margin-right: $base-spacing; }
    }
    @include media($tablet-landscape) {
      float: none;
      width: rem(150);
      padding: 0;

      margin: 0 $base-spacing $base-spacing*3 $base-spacing;
      &:last-of-type{ margin-bottom: $base-spacing*3; }
    }
  }

  li{
    font-size: 1rem;
    position: relative;

    line-height: rem(20);
    text-align: left;
    text-transform: lowercase;

    height: rem(80);

    @include media($phone) { br.opt { display: none;} }
    @include media($tablet-landscape) {
      height: rem(130);
      width: rem(150);
    }

    .subbrand_hero--submenu--flavor_name{
      position: relative;
      z-index: 100;
    }
    .subbrand_hero--submenu--flavor_descriptor{
      display:block;
      position: relative;
      z-index: 100;

      font-size: 0.6em;
      font-weight: 400;
      letter-spacing: rem(2);
      text-transform: uppercase;
    }
    .subbrand_hero--submenu--flavor_thumbnail{
      overflow: hidden;
      height: 100%;

      z-index: 0;
      position: absolute;
      bottom:0; right: $base-spacing * -1;

      @include media($tablet-landscape) {
        height: 70px;
        bottom:0; left:0; right:0;
      }

      & > img{
        position: relative;
        transition: bottom $base-duration $base-timing;
        height: 100%;
        bottom: 0px;
        @include media($tablet-landscape) { bottom: -10px; }
      }
    }
    &:hover .subbrand_hero--submenu--flavor_thumbnail > img { bottom: 0; }
  }

}
