@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  // -webkit-transform: perspective(1px) translateY(-50%);
  // -ms-transform: perspective(1px) translateY(-50%);
  // transform: translateY(-50%);

  transform: perspective(1px) translateY(-50%);
}

@mixin XYcenter($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  // -webkit-transform: translate(-50%, -50%);
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);

  transform: perspective(1px) translate(-50%, -50%);
}


@function rem($v){
  @return 1rem * ( $v / strip-unit( $base-font-size ) )
};

@function em($v){
  @return 1em * ( $v / strip-unit( $base-font-size ) )
};
