@mixin stripes(){
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left:0;
  right:0;

  svg{
    position: relative;
    left:-100%;
    width: 300%;

    @include media($tablet-portrait) {
      left:-75%;
      width: 250%;
    }
    @include media($tablet-landscape) {
      left:-50%;
      width: 200%;
    }
    @include media($desktop) {
      left:0;
      width: 100%;
    }
  }
}
