section.subbrand_hero.slightly-sweet ul.subbrand_hero--submenu{
  @include media($tablet-landscape) { max-width: 900px; }

  @include media($big-desktop) { max-width: 100%; }
}


section.subbrand_hero.slightly-sweet#slightly-sweet--page-hero{
  div.subbrand_hero--content{

    .subbrand_hero--copy-wrap{
      @include container;

    }

    .subbrand_hero--image-wrap{
      display: block;
      position: absolute;
      bottom:0;
      top: 0;
      left: 0;
      right: 0;

      opacity: 0.5;
      @include media($tablet-portrait) { opacity: 1; }


      .subbrand_hero--image-container {
        text-align: right;
        height: 100%; width: 100%;
        img{
          width:  auto;
          height: 100%;
          max-width: initial;
        }
      }

    }

  }
}
