article header.page--header{}

article header.page--header .page--header__content{
  //@include container-small;
  padding-top:    $base-spacing*3;
  padding-bottom:    $base-spacing;

  header{ @extend h1; }
}

article section{
  //@include container-small;
  // padding-bottom: $base-spacing*3;
}
