.instagram-embed{
  visibility: hidden;

  header{
    @extend h3;
    text-align: center;
    margin: 0 auto $base-spacing*4 auto;
  }
  footer{
    display: block;
    padding-top: $base-spacing;

    text-align: center;
    font-weight: 500;
    // font-size: rem(14);
    // text-transform: uppercase;
  }

  @include clearfix;
  @include container-no_padding;

  //padding: 0 $small-spacing;
  padding: 0 ($base-spacing - ($small-spacing * .5) + rem(1));
  margin-bottom: $base-spacing*2;
}

.instagram-embed .instafeed-wrap{
  // overflow: hidden;

  a{
    display: inline-block;
    font-size: 0;



    padding: $small-spacing * 0.5;

    float: left;
    height: auto;
    width: 50% ;


    @include media($tablet-portrait)  { width: 33.333%; }
    @include media($desktop)          { width: 25% ; }

    transition: opacity $base-duration*1.5 $base-timing;

    .img_wrap{
      position: relative;
      //background-color: pink;
      padding-top: 100%;
      width: 100%;

      overflow: hidden;

      img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        &.landscape{
          // height: 100%;
          // min-width: auto;
        }
      }
    }
  }

  &:hover > a { opacity: 0.4; }
  &:hover > a:hover { opacity: 1; }
}
