$arrow-size: .25rem;
// $arrow-size-phone: .25rem;
$arrow-size-tablet: rem(4);

nav.subnav-breadcrumbs{
  @include container;
  margin-top: $base-spacing*1.5;
  margin-bottom: $base-spacing*1.5;

  color: $black;

  font-family: $gotham;
  font-weight: 600;
  line-height: $base-spacing;
  text-transform: uppercase;

  font-size: rem(10);
  letter-spacing: em(1);

  @include media($tablet-landscape) {
    margin-top: $base-spacing*3;
    margin-bottom: $base-spacing*3;

    font-size: rem(12);
    letter-spacing: em(2);
  }

  ul{
    display: block;

    li{
      display: inline-block;

      padding-right: $small-spacing;
      @include media($tablet-landscape) { padding-right: $base-spacing; }
      //adding-right: $base-spacing;

      a{
        color: $medium-gray;

        &:active,
        &:focus,
        &:hover {
          color: $action-color;
        }
      }

      &:before{
        display: inline-block;
        position: relative;
        width: 0; height: 0;

        right: $small-spacing/2 + $arrow-size/2;
        content: "";

        border-top: $arrow-size solid transparent;
        border-bottom: $arrow-size solid transparent;
        border-left: $arrow-size solid $medium-gray;

        @include media($tablet-landscape) {
          right: $base-spacing/2 + $arrow-size-tablet/2;
          border-top: $arrow-size-tablet solid transparent;
          border-bottom: $arrow-size-tablet solid transparent;
          border-left: $arrow-size-tablet solid $medium-gray;
        }

      }

    }

    li.subnav-breadcrumbs__last:after{ display: none; }
    li.subnav-breadcrumbs__home:before { display: none; }
  }

  &.invert {
    margin-top: $base-spacing*1.5;
    padding: 0;
    ul li a { color: $white; }
    ul li:before { border-left-color: $white; }

    @include media($tablet-landscape) {
      margin-top: $base-spacing*2;
    }
  }
}
