//Fonts
$brandon: "brandon-grotesque", Helvetica, Arial, sans-serif;
$gotham: "Gotham SSm A", "Gotham SSm B", "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;


// Typography
$base-font-family: $gotham;
$heading-font-family: $brandon;

$base-font-weight: 300;

//Modular Scale
$ms-range:
  //Scale           //Breaking point
  $major-third        $phone-v ,
  $augmented-fourth   $desktop-v;//$tablet-landscape-v;// ,
  //$golden             $desktop-v ;


body {
  color: $base-font-color;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $gotham;
  font-weight: $base-font-weight;

  text-rendering: optimizeLegibility;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:focus{
    color: shade($action-color, 40%);
  }

  @include media($tablet-landscape) {
    &:active,
    &:hover {
      color: shade($action-color, 40%);
    }
  }
}

a.inverted {
  color: $black;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:focus{
    color: $action-color;
  }

  @include media($tablet-landscape) {
    &:active,
    &:hover {
      color: $action-color;
    }
  }
}

%heading{
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  letter-spacing: em(1);

  margin: 0 0 $small-spacing;

  text-transform: uppercase;
  font-weight: 900;

  font-size: 0.9rem;

  text-rendering: geometricPrecision;
}

h1,h2,h3,h4,h5,h6 { @extend %heading; }
h1 { @include ms-respond(font-size, 5); }
h2 { @include ms-respond(font-size, 4); }
h3 { @include ms-respond(font-size, 3); }
h4 { @include ms-respond(font-size, 2); }
h5 { @include ms-respond(font-size, 1); }
h6 { @include ms-respond(font-size, 0); }

%navigation{
  font-family: $gotham;
  letter-spacing: em(2);
  line-height: 1;
  text-transform: uppercase;
  //text-align: center;
}

p {
  margin: 0 0 $base-spacing;
  font-size: 1rem;
  //@include ms-respond(font-size, 0);
  strong{
    font-weight: 700;
  }
}


blockquote{
  border-left: $base-border;
  padding-left: $base-spacing;

  //cite{ font-size: 0.85em; }
  cite::before { content: "\2014\A0" ;}
}

mark, ::selection {
    background-color: $medium-gray;
    //color: $white;
}

u, abbr[title]{
  text-decoration: none;
  border-bottom: 1px dotted $base-font-color;
}

abbr{
  font-size: 0.9em;
  letter-spacing: em(3);
}

ins {}

del { color: $light-font-color; }

small {
  letter-spacing: rem(.5);
  font-size: 0.6rem;
}
