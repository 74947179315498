@import "instagram";

article.home header.page--header .page--header__content{}
//article.home section{ @include container; }

article.home{

  @include media($tablet-portrait) { margin-top: ($nav-height + $nav-padding*1.5) * -1; }

  header.page--header{
    .page--header__content{
      //background: pink;
      position: relative;
      z-index: 500;
      overflow: hidden;

      padding-top:0;
      padding-bottom:0;

      .home_hero--copy-wrap{
        padding-top: $base-spacing*6;
        @include media($tablet-portrait) { padding-top: $base-spacing*10; }
        @include media($tablet-landscape) { padding-top: $base-spacing*14; }

        position: relative;
        z-index: 505;

        header.home_hero--copy__header {
          @include container;
          @extend h2;

          @include media($tablet-portrait) { @include ms-respond(font-size, 5); }

        }
        .home_hero--copy__body{
          @include container;
          background-color: white;

          padding-top:$base-spacing*1.5;
          padding-bottom: $base-spacing*2;

          @include media($tablet-portrait) {
            background-color: transparent;
            padding-top:0;
            padding-bottom: $base-spacing*4;
          }

          p{
            font-size: rem(20);
            max-width: 26rem;

            @include media($tablet-portrait) { font-size: rem(22); }
            @include media($tablet-landscape) { max-width: 35rem; }

          }
          button {
            @extend button.ghost;
            @include media($tablet-portrait) { font-size: rem(18); }
            margin-top: $base-spacing;
          }
        }
      }

      .home_hero--image-wrap{
        display: block;
        position: absolute;
        //bottom:0;
        top: 0;
        left: 0;
        right: 0;

        height: 60%;
        @include media($phone) { height: 55%; }
        @include media($tablet-portrait) { height: 100%; }

        &:before{
          content: "";
          display: block;
          position: absolute;
          top:0; bottom: 0; left: 0; right:0;
          z-index: 1;

          transition: background-color $base-duration $base-timing;
          background: transparent;

          @include media($phone) {
            background: linear-gradient(0deg, rgba(255,255,255, 0.6), rgba(255,255,255, 0) );
          }
          @include media($tablet-portrait) {
            background: linear-gradient(90deg, rgba(255,255,255, 0.4) 35%, rgba(255,255,255, 0) 80%);
          }
          @include media($desktop) {
            background: transparent;
          }
        }

        .home_hero--image-container {
          position: absolute;
          top:0; bottom: 0; left: 0; right:0;
          z-index: 0;

          text-align: right;
          height: 100%; width: 100%;

          //display: none;
          background-color: $white;

          visibility: hidden;
          opacity: 0;

          transition: visibility 2000ms, opacity 2000ms $base-timing;

          &.js-active{
            visibility: visible;
            opacity: 1;
          }

          img{
            width:  auto;
            height: 100%;
            max-width: initial;
          }
        }
      }
    }
  }

  section.home--romance-wrap{
    // overflow: hidden;
    position: relative;
    z-index: 1;

    background-color: $white;

    div.home--romance{
      @include clearfix;
      @include container-small;
      position: relative;

      padding-top: $base-spacing*2;

      // padding-bottom: $base-spacing*2;
      //
      @include media($tablet-portrait) {

        margin-bottom: $base-spacing*-2;
      }
      // @include media($tablet-landscape) {
      //   padding-top: $base-spacing*2;
      //   padding-bottom: $base-spacing*2;
      // }
      // @include media($desktop) {}
      // @include media($big-desktop) {}
    }

    div.home--romance__content{
      width: 100%;
      float: right;
      // overflow: hidden;
      position: relative;
      z-index: 1;

      p{ font-size: rem(18);
        //margin-bottom: $base-spacing*2;
        line-height: 1.4;
      }

      button{
        @extend button;
        margin-top: $base-spacing;
      }


      @include media($tablet-portrait) {
        button{ font-size: 0.9em; }

        @include vertical-align(absolute)

        right: $base-spacing;
        width: 50%;
        padding-left: $base-spacing*2;
      }
    }

    div.home--romance__image{

      .home--romance__image-wrap{
        max-width: rem(430);
        margin: 0 auto;

        @include media($tablet-portrait) {
          max-width: initial;
          width: 50%;
          float: left;
        }
        img{
          width:  100%;
          height: auto;
          max-width: initial;
        }


      }
    }

    // div.home--romance__background{
    //   @include stripes;
    //   z-index: -1;
    //   svg#bg_stripes{
    //     height: 150%;
    //     polygon#s1 { fill: darken($offwhite, 0%); }
    //     polygon#s2 { fill: darken($offwhite, 1%); }
    //     polygon#s3 { fill: darken($offwhite, 0%); }
    //     polygon#s4 { fill: darken($offwhite, 2%); }
    //     polygon#s5 { fill: darken($offwhite, 1%); }
    //   }
    // }
  }

  section.home--bullets-wrap{
    @include clearfix;

    //background-color: $gray;
    @include paper-background();

    color: $black;
    svg{
      circle,
      polygon,
      path{
        fill: $black;
      }
    }

    padding-top: $base-spacing*2;
    padding-bottom: $base-spacing;

    @include media($tablet-portrait) {
      padding-top: $base-spacing*3;
      padding-bottom: $base-spacing*2;
    }

    div.home--bullets{ @include container-small; }

    span.home--bullets__header{
      display: block;
      text-align: center;
      margin:0 auto $base-spacing auto;

      color: $gold;
      text-transform: uppercase;
      font-size: rem(14);
      font-weight: 500;
      letter-spacing: em(2);
    }

    ul.home--bullets__list{
      display: block;
      li{
        @include clearfix;

        display: block;
        width: 100%;
        text-align: center;
        float: left;
        padding-bottom: $base-spacing*2;

        .icon{
          display: block;
          float: none;

          height: $base-spacing*3;
          //height: $base-spacing*3;

          margin-left: auto;
          margin-right: auto;
          margin-top: $small-spacing;

          //margin-bottom: $small-spacing;
          svg {
            height: 100%;
            width: auto;

            path{ fill: $gold;}
          }
        }
        .header{
          float: none;
          display: block;
          line-height: 1.2;
          br.opt{ display: block;}

          text-transform: uppercase;
          font-size: rem(16);
          font-weight: 600;
          letter-spacing: em(2);

          margin: $base-spacing 0;
        }
        .body{
          max-width: 18rem;
          margin: 0 auto;
        }
      }

      @include media($tablet-portrait) {
        li{
          display: inline-block;
          width: 33.333%;

          .icon{
            width: auto;
            height: $base-spacing*3;
          }
        }
      }
      @include media($tablet-landscape) {}
      @include media($desktop) {}
      @include media($big-desktop) {}
    }

    p.home--bullets__copy{
      max-width: 30rem;
      text-align: center;

      margin:0 auto $base-spacing auto;

      // @include media($tablet-portrait) {
      //   max-width: 30rem;
      //   text-align: center;
      // }

    }
  }

  div.instagram--social-wrap{
    text-align: center;
    padding-top:    $base-spacing*2;
    padding-bottom: $base-spacing*4;
    // background: $black;
    // color: $gold;

    span.header{
      display: inline-block;
      margin-bottom: $small-spacing;

      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: em(2);
    }

    .nav--menu--social{
      display: block;

      & li{
        display: inline-block;
        height: 3rem;
        width: 3rem;
        padding: 0.5rem;

        // border: rem(3) solid white;
        // background-color: white;
      }


      & a.nav--menu--socialicon{
        margin-right: $base-spacing;
        &:last-of-type { margin-right: 0; }


        li svg path,
        li svg circle,
        li svg polygon{
          transition: fill $base-duration $base-timing;
        }

        &:hover,
        &:active,
        &.js-active:hover,
        &.js-active,
        &:focus{
          color: $gold;

          li svg path,
          li svg circle,
          li svg polygon{ fill: $gold; }
        }
      }

    }
  }
}
