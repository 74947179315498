section.subbrand_hero ul.subbrand_hero--submenu a#chamomile-tea--thumbnail{
  .subbrand_hero--submenu--flavor_descriptor{ color: #63b803}
}

section.product_info.unsweetened{
  .product_info--sub_brand{
    @include media($tablet-landscape) { height: $base-spacing*2.333; }
  }

  &#chamomile-tea{
    .product_info--header--descriptor{ color: #63b803}
  }
}

section.subbrand_hero.unsweetened#unsweetened--page-hero{


  ul.subbrand_hero--submenu{
    @include media($tablet-landscape) { max-width: 1000px;}
  }

  div.subbrand_hero--content{

    .subbrand_hero--copy-wrap{
      @include container

      span.subbrand_hero--headline{
        @include ms-respond(font-size, 3);
        
        @include media($tablet-portrait) {
          @include ms-respond(font-size, 5);
          margin-left: rem(-6);
        }
        @include media($tablet-landscape) {}
        @include media($desktop) {}
        @include media($big-desktop) { }

      }
    }
    .subbrand_hero--image-wrap{

      display: block;
      position: absolute;
      bottom:0;
      top: 0;
      left: 0;
      right: 0;

      opacity: 0.5;
      @include media($tablet-portrait) { opacity: 1; }


      .subbrand_hero--image-container {
        text-align: right;
        height: 100%; width: 100%;
        img{
          width:  auto;
          height: 100%;
          max-width: initial;
        }
      }

    }
  }
}
