article.e404 header.page--header .page--header__content{ @include container-small; }
article.e404 section{ @include container-small; }

article.e404{
  //background: $black;
  color: $black;
}
article.e404 header.page--header{
  text-align: center;
  span {
    //@extend %navigation;
    font-family: $gotham;
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: em(2);
    padding-bottom: $base-spacing*0.5;
    border-bottom: $base-border;
    border-color: $black;
  }
  //
  // background: url("/img/test.jpg");
  // background-size: 100%;
  // color: $white;
}


.e404--content-wrap{
  padding-top: $base-spacing*2;
  padding-bottom: $base-spacing*2;

  width: 95%;
  // padding-bottom: 80%;


  span{
    // font-family: $brandon;
    display: block;
    text-align: center;
    padding: $base-spacing*2 0;
  }
}
.e404-content{
    background-color: $offwhite;
    height: 100%;
    width: 100%;
    max-height: rem(400);
    max-width: rem(400);
    margin: 0 auto;
    border-radius: 50%;

    img{
      height: 100%;
      width: 100%;
    }


}
