$m-nav-height: 3rem;
$m-nav-padding: $small-spacing;

header.nav--mobile-header,
nav.nav--mobile-tabbar{
  display: block;

  left: 0; right: 0;

  overflow:none;

  width: 100%;
  height: $m-nav-height;

  background-color: white;

  @extend %navigation;
  font-size: rem(10.5);
  font-weight: 700;
}

header.nav--mobile-header{
  position: fixed;
  top: 0 ;

  border-bottom: $secondary-border;
  padding: $m-nav-padding $base-spacing;
  //text-align: left;

  .page__logo-main{
    display: inline-block;
    height: $m-nav-height * 1.5;
    width: $m-nav-height * 1.5;

    //padding: .25rem 0 ;
    background-color: white;

    svg {
      width: auto;
      height: auto;

      g #TeasTea > *,
      g #Organic > * { fill: $base-font-color; }
      g #BoxBorder > * { fill: $base-font-color; }
      g #BG {fill: $white;}
    }
  }

  .nav--mobile-header__shopnow{
    @extend button;
    display: block;
    float: right;
  }
}

nav.nav--mobile-tabbar {
  position: fixed;

  bottom: 0;
  border-top: $secondary-border;
}

nav.nav--mobile-tabbar div.nav--mobile-tabbar__wrap{
  max-width: $tablet-portrait-v*2;
  margin: 0 auto;

  height: 100%;
}

nav.nav--mobile-tabbar ul.primary-list {
  width: 88%;
  &.two-items a{
    width: 50%;
    &:last-of-type{ border-left: rem(1) solid $offwhite; }
  }
  a{
    color: $black;
    text-align: center;
    display: inline-block;
    float: left;

    width: 33.3333%;
    //height: $m-nav-height;

    line-height: $m-nav-height - rem(7); //minus 7rem for the :before border (see below)



    &:active,
    &.js-active,
    &:focus,
    &:hover {
      color: $gold;

      li svg path,
      li svg polygon{ fill: $gold; }
    }

    &:before{
      display: block;
      margin: 0 auto;
      content: "";
      background-color: inherit;
      height: rem(3);
      width: 0;

      transition: width $base-duration $base-timing;
    }
    &.js-active:before{ background-color: $gold; width: 100%; }
  }
}

nav.nav--mobile-tabbar .nav--mobile-tabbar__toggle{
  color: $black;
  text-align: center;
  display: inline-block;
  float: right;

  width: 12%;
  border-left: rem(1) solid $offwhite;
  line-height: $m-nav-height; //minus 7rem for the :before border (see below)
  padding-top: rem(3.5);

  transition: background-color $base-duration $base-timing;

  svg{
    width: $small-spacing*1.5;
    height: $small-spacing*1.5;
    g rect {
      fill: $black;
    }
  }

  &.js-active{
    background-color: $action-color;
  }
}

div.nav--mobile-tabbar.secondary-list{
  @extend .overlay;
  //position: relative;
  width: 100%;
  height: 100%;

  padding: $base-spacing;
  bottom: rem(2);
  background-color: $black;

  .page__logo-main{
    display: block;
    height: $m-nav-height * 2;
    width: $m-nav-height * 2;
    margin: 0 auto;

    //position: absolute;
    //top: $base-spacing; left: $base-spacing;

    svg {
      width: auto;
      height: auto;

      g #TeasTea > *,
      g #Organic > * { fill: $white; }
      g #BoxBorder > * { fill: $white; }
      g #BG {fill: $black;}
    }

  }
  .nav--mobile-tabbar__drawer {
    position: fixed;
    top: 0; bottom:0; left:0; right:0;

  }
  .nav--mobile-tabbar__menuwrap{
    overflow-y: scroll; /* has to be scroll, not auto */
    &.overflow-scroll{ -webkit-overflow-scrolling: touch; }

    position: absolute;
    top: $m-nav-height * 2  + $base-spacing * 2;
    left: 0; right: 0;
    bottom: $m-nav-height;

    & .products-submenu_title {
      display: block;

      font-size: rem(12);
      font-weight: 500;
      letter-spacing: em(2);
      text-transform: uppercase;
      text-align: center;
      color: white;

      padding: $base-spacing*.5 0;
      margin: 0 $base-spacing;
      border-bottom: 1px solid $white;
    }

    & .products-submenu{
      text-align: left;
      padding: 0 $base-spacing;

      a.products-submenu__head{
        @extend %navigation;
        font-size: rem(12);
        font-weight: 700;
        display: block;
        height: $base-spacing * 2.75;
        line-height: $base-spacing * 2.75;
      }

      ul.products-submenu__list{
        font-weight: 400;
        font-size: rem(14);
        padding-bottom: $base-spacing * 2.75;

        a{
          display:block;
          height: $base-spacing * 2.75;
          line-height: $base-spacing * 2.75;
          padding-left: $small-spacing;
          border-bottom: 1px solid #272727;
          &:first-of-type{ border-top: 1px solid #272727; }
          &.js-active li{
            font-style: italic;
            position:relative;
            padding-left: 1rem;
            color: white;

            &:before{
              content:"";

              position: absolute;
              left:rem(-2); top: 1.5rem;

              display: block;
              float: left;
              width: 0.7rem;
              height: 0.7rem;
              background-size: 0.7rem;
              background: url("/img/icons/right-arrow.svg");
              background-repeat: none;
            }
          }
          &.js-active:hover li{ color: $action-color; }

          // li{ line-height: 1.2; }
        }
      }
    }
  }

  .nav--menu--social__wrap{
    display: block;

    position: absolute;
    bottom:0; left:0; right:0;
    text-align: center;

    border-top: $secondary-border;
    border-color: $medium-gray;

    & .nav--menu--social{
      float: left;
      width: 88%
    }
    & a.nav--menu--socialicon{
      display: inline-block;
      float: left;

      height: $m-nav-height;
      line-height: $m-nav-height+1rem;
      width: 25%;

      &{
        li svg path,
        li svg circle,
        li svg polygon{ fill: $white; }
      }
      &:hover,
      &:active,
      &.js-active:hover {
        li svg path,
        li svg circle,
        li svg polygon{ fill: $gold; }
      }

      &.js-active,
      &:focus{
        li svg path,
        li svg circle,
        li svg polygon{ fill: $gold; }
      }

    }

    & li{
      display: inline-block;

      height: $base-spacing*1.25;
      width: $base-spacing*1.25;
    }
  }

  .nav--mobile-tabbar__toggle{
    color: $black;
    text-align: center;
    display: inline-block;
    float: right;

    width: 12%;
    border-left: rem(1) solid $medium-gray;

    height: $m-nav-height; //minus 7rem for the :before border (see below)
    line-height: $m-nav-height; //minus 7rem for the :before border (see below)
    padding-top: rem(3.5);

    transition: background-color $base-duration $base-timing;

    svg{
      width: $small-spacing*1.5;
      height: $small-spacing*1.5;
      g rect {
        fill: $black;
      }
    }

    &.js-active{
      svg g rect {
        fill: $action-color;
      }
    }
  }
  .nav--mobile-tabbar__toggle--close{
    display:block;
    width: $base-spacing;
    height: $base-spacing;

    position: absolute;
    top: $base-spacing - rem(3); right: $base-spacing - rem(3);
    z-index: 1001;
    cursor: pointer;
    svg polygon { fill: $white; }
  }
}
