#{$all-buttons} {
  appearance: none;
  background-color: $action-color;
  border: 0;
  border-radius: $base-border-radius;
  color: $white;
  cursor: pointer;
  display: inline-block;
  position: relative;

  -webkit-font-smoothing: antialiased;
  line-height: 1;
  padding: $small-spacing*1.25 $base-spacing;
  &, &:before, &:after{ transition: all $base-duration $base-timing; }
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  @extend %navigation;
  // font-family: $base-font-family;
  font-size: rem(14);
  font-weight: 800;
  // text-decoration: none;
  // text-align: center;

  width: 100%;
  @include media($tablet-portrait) { width: auto; }


  &:focus {
    background-color: shade($action-color, 20%);
    color: #fff;
  }

  &:disabled { opacity: 0.5; }

  @include media($tablet-landscape) {
    &:hover{
      background-color: shade($action-color, 20%);
      color: #fff;
    }
    &:disabled {
      opacity: 0.5;

      &:hover {
        background-color: $action-color;
      }
    }

  }
}

button{
  span.text{
    vertical-align: middle;
    display: inline-block;
    height: 1em;
  }
  span.icon{
    vertical-align: middle;

    display: inline-block;
    height: 1em;
    width: .78em;
    margin-right: -0.25em;

    position: relative;
    top: em(-.5);

    svg{
      height: 100%;
      polygon { fill: $white; }
    }
  }
  &:before{
    content: " ";
    display: block;
    position: absolute;
    top:0; bottom: 0; left: 0;
    z-index: -1;

    width: 0;

    background-color: $black;

    text-align: left;
    transition-property: width;
  }

  @include media($tablet-landscape) {
    &:hover,
    &:focus {
      color: $gold;
      transform: translate(0, rem(-3));
      box-shadow: 0 rem(8) rem(10) rem(-5) rgba(0,0,0,.25);

      &:before{ width: 100%; }

      span.icon{
        svg polygon { fill: $gold; }
      }
    }
  }
}

button.invert{
  background-color: $black;
  //border: $base-border;

  color: $gold;
  span.icon svg polygon { fill: $gold; }

  @include media($tablet-landscape) {
    &:hover,
    &:focus {
      color: $white;
      span.icon svg polygon { fill: $white; }
      &:before{
        background-color: $gold;
      }
    }
  }
}

button.ghost{
  background-color: transparent;
  border: $base-border;

  color: $black;
  span.icon svg polygon { fill: $black; }

  &.inverted{
    color: $white;
    span.icon svg polygon { fill: $white; }
  }

  @include media($tablet-landscape) {
    &:hover,
    &:focus {
      color: $gold;
      span.icon svg polygon { fill: $gold; }
    }

    &.inverted{
      &:hover,
      &:focus {
        color: $black;
        span.icon svg polygon { fill: $black; }
      }

    }
  }
}
