$max-box-width:  $base-spacing*17;

@each $class, $map in $flavors-map {
  section.product_info##{$class}{
    $bg-color: map-get($map, bgcolor);
    $text-color: map-get($map, text);
    $link-color: map-get($map, link);
    $link-color--hover: map-get($map, link-hover);
    $highlight-color: map-get($map, inverted-color);
    // $link-color: transparentize(map-get($map, text),0.3);
    // $link-color--hover: darken(map-get($map, text), 8%);

    color: $text-color;
    background-color: $bg-color;

    nav.subnav-breadcrumbs.invert .subnav-breadcrumbs__last{
      color: $text-color;
    }

    %inverted-box{
      color: $white;
      background-color: $link-color;

      .icon{
        svg path,
        svg polygon,
        svg rect {
          fill: $bg-color;
        }
      }
    }

    %highlight-color {color: $highlight-color; }
    %border-color{ border-color:  transparentize(map-get($map, text),0.4); }

    %sku-a{
      color: $link-color;
      transition: color $base-duration $base-timing;
      cursor: pointer;

      svg path,
      svg polygon,
      svg rect {
        transition: fill $base-duration*.5 $base-timing;
        fill: $link-color;
      }

      &:hover,
      &:focus {
        color: $link-color--hover;
        svg path,
        svg polygon,
        svg rect { fill: $link-color--hover;}
      }
      &.js-active {
        color: $highlight-color;
        border-color: $highlight-color;

        svg path,
        svg polygon,
        svg rect { fill: $highlight-color;}
      }

      span.label:after{ background-color: $highlight-color; }
    }

    %sku-a--alt{
      color: $highlight-color;
      transition: color $base-duration $base-timing;
      cursor: pointer;

      svg path,
      svg polygon,
      svg rect {
        transition: fill $base-duration*.5 $base-timing;
        fill: $highlight-color;
      }

      &:hover,
      &:focus {
        color: $link-color--hover;
        svg path,
        svg polygon,
        svg rect { fill: $link-color--hover; }
      }
      &.js-active {
        color: $black;

        svg path,
        svg polygon,
        svg rect { fill:$black; }
      }
    }

    %sku-svg {
      svg path,
      svg polygon,
      svg rect {
        fill: $text-color;
        transition: fill $base-duration*.5 $base-timing;
      }
    }

    .product_info--background {
      svg{
        polygon#low { fill: map-get($map, bgcolor-low) }
        polygon#mid { fill: map-get($map, bgcolor-mid) }
        polygon#top { fill: map-get($map, bgcolor-top) }
      }
    }
  }
}

.product_info--sub_brand{
  @extend %highlight-color;
  font-family: $gotham;
  font-weight: 400;
  font-size: rem(14);
  letter-spacing: rem(2);

  margin-bottom:0;
  padding-bottom: 0;

  height: $base-spacing*2;

  @include media($tablet-landscape) { height: $base-spacing*2; }
}

.product_info--background {
  @include stripes;
}

.product_info--header {
  & > * {font-family: $gotham !important;}
  .product_info--header--descriptor{
    @include ms-respond(font-size, 1);
    font-weight: 500;
    line-height: 1;
    margin-bottom: .25em;

    @include media($tablet-landscape) {
      @include ms-respond(font-size, 2);
      margin-left: rem(1); //nudge to align with left margin
    }

  }
  .product_info--header--name{
    @include ms-respond(font-size, 4);
    margin-bottom: $base-spacing;

    font-weight: 400;
    line-height: 1.1;

    letter-spacing: rem(-2);
    text-transform: lowercase;

    margin-bottom: $base-spacing;
    margin-left: rem(-4); //nudge to align with left margin

    @include media($phone) { @include ms-respond(font-size,4); }
    @include media($tablet-landscape) {
      margin-bottom: $base-spacing*.75;
      line-height: 1.1;
      margin-left: rem(-4); //nudge to align with left margin
    }

  }

  &.js-smaller{
    @include media($tablet-landscape) {
      br.opt{ display: none; }
      .product_info--header--descriptor { @include ms-respond(font-size, 1);}
      .product_info--header--name {
        @include ms-respond(font-size, 3);
        margin-left: rem(-4); //nudge to align with left margin
      }
    }
  }
  .product_info--header--legal{}
}

.product-subpages-wrap{
  p{ @include media($tablet-landscape) { max-width: 25em; } }
}

.product_info--romance{
  font-size: .9rem;
  //max-width: 25em;

  @include media($tablet-landscape) {
    //max-width: 25em;
    font-size: rem(20);
  }
}

.product_info--bullets{
  li{
    margin-bottom: $base-spacing;

    $icon-size-mobile: 1.5rem;
    $icon-size-desktop: 2rem;

    div.text{
      font-size: .8rem;

      min-height: $icon-size-mobile;
      max-width: 24em;

      margin-left: $icon-size-mobile + 1rem;

      @include media($tablet-landscape) {
        font-size: 1rem;
        max-width: 24em;

        min-height: $icon-size-desktop;
        margin-left: $icon-size-desktop + 1rem;
      }
    }

    div.icon{
      @extend %sku-svg;
      float: left;

      margin-top: rem(4);//visual adjustment
      //margin-right: $small-spacing;

      width: $icon-size-mobile;
      height: $icon-size-mobile;

      @include media($tablet-landscape) {
        //margin-right: $base-spacing;

        width: $icon-size-desktop;
        height: $icon-size-desktop;
      }
    }
  }
}

.product_info--images{
  @extend %border-color;
  border-style: solid;
  border-width: rem(1) 0;

  text-align: center;

  .product_info--images--wrap{
    span.label{
      font-weight: 500;
      font-size: rem(12);
      display: inline-block;
      line-height: 1.4;
    }
  }
}

.product_info--certs{
  @include media($tablet-portrait){ width: 17%;}

  .product_info--certs--organic,
  .product_info--certs--non_gmo{
    height: 100%;

    & img{
      height: 100%;
      max-width: 5rem;
    }
  }
}

.product_info--sizes{}

.product_info--sublabel{
  text-transform: uppercase;
  font-weight: 500;
  font-size: rem(12);
  letter-spacing: em(1);
  line-height: 1;
}

.product_info--haiku--wrap{
  @include media($tablet-landscape) { max-width: $max-box-width; }

  background-color: $white;
  color: $black;

  padding: $base-spacing;
  margin-bottom: $base-spacing;

  p {
    margin-bottom: $small-spacing;
    font-size: rem(14);
    @include media($tablet-landscape) {  font-size: 1rem; }

  }
  cite{
    display: block;
    font-size: 0.9em;
    text-align: right;

    font-size: rem(14);
    @include media($tablet-landscape) {  font-size: 1rem; }
  }
}

.product_info--haiku--description{}

.product_info--nutrition--wrap{
  @include media($tablet-landscape) { max-width: $max-box-width; }
}

.product_info--nutrition--description{}

.product_info--ingredients{
  position: relative;
  color: $black;
  background-color: $white;
  padding: $small-spacing $base-spacing*.8 $base-spacing*.8 0;
  width: 100%;
  min-height: 5.5rem;

  p{
    font-size: .8rem;
    @include media($tablet-landscape) { font-size: .8rem; }

    margin-left: $base-spacing*3.25;
    margin-bottom: 0;
  }

  .product_info--sublabel{ margin-left: $base-spacing*3.25; }
  .product_info--nutrition--calorie{
    position: absolute;
    left:  $small-spacing; //visual adjustment
    height: rem(61.7); //specify to fix ie sizing
    width: 3rem;
  }
}

.product_info--benefits{
  @include clearfix;
  @extend %inverted-box;

  position: relative;
  padding: $small-spacing;

  width: 100%;
  height: 2.5rem;
  min-height: 1.5rem + $small-spacing*2;

  display: block;

  @include media($small) { height: 4rem; }

  @include media($tablet-landscape) { display: inline-block; }

  p{
    @include vertical-align(absolute);

    display: inline-block;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 500;
    font-size: rem(12);
    letter-spacing: em(1);
    margin-bottom: 0;
    margin-left: $small-spacing*2 + 1rem;

    //background-color: pink;
    float: left;
  }

  .icon{
    display: inline-block;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: $small-spacing; left:$small-spacing;

    svg { height: 100%; }
  }

}

.product_info--nutfacts_tables{
  margin-top: $base-spacing;

  @include media($tablet-portrait) {
    display: none;
  }
  //padding-top: $base-spacing*2;
  text-align: center;

  .product_info--nutfacts_table--wrap{
    display: inline-block;
    vertical-align: top;
    padding: $base-spacing*0.5;

    .product_info--sublabel{
      display: inline-block;
      text-align: left;
      width: 100%;
    }

    .product_info--nutfacts_table{
      background-color: $white;
      border: rem(2) solid $black;

      //margin-bottom: $base-spacing;
      max-width: rem(284);
    }
  }
}

.product_info--nutfacts--toggle{
  @extend %sku-a--alt;
  display: none;

  margin-top: $base-spacing;

  @include media($tablet-portrait) { display: block; }

  & > span{
    text-decoration: underline;
  }
  .icon{
    display: inline-block;
    height: 1rem; width: 1rem;
    position: relative; top: rem(2);
    margin-right: $small-spacing *.5;

  }
}

.product_info--buycta{
  button.buycta{
    //@extend button;
    // display: block;
    // box-sizing: border-box;
    color: $gold;
    background: $black;

    width: 100%;
    @include media($tablet-portrait) { width: 18rem;  }

    font-size: rem(12);
    padding: 0;
    margin-bottom: $base-spacing;
    transition-property: all;
    box-shadow: 0 0 rem(5) rem(-5) rgba(0,0,0,.85);
    height: $base-spacing*2 ;
    line-height: 1;

    span.icon{
      svg polygon { fill: $gold; }
    }

    &:before{
      background-color: $white;
    }
    &:hover,
    &:focus {
      color: $black;
    }


    .buycta--label{
      width: 55%;
      width: calc(100% - 8.4rem);
      float: left;
      height: 100%;

      &:before{
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      span.text{
        color: $gold;
      }
    }

    .buycta--store{
      height: 100%;
      width: 45%;
      max-width: $base-spacing*6;

      float: right;

      font-size: rem(16);
      text-transform: lowercase;
      letter-spacing: normal;
      font-weight: 400;
      line-height: $base-spacing*2;

      &.itoen{
        background-color: $itoen-website;
        color: $white;
      }
      &.amazon{
        background-color: #efc858;
        color: #2D2D2D;
      }
    }
  }

  .buycta--link{
    @extend %sku-a--alt;
    text-decoration: underline;
    font-size: rem(14);

    span.icon{
      display: inline-block;
      //height: $base-spacing*2;
      width: .7em;
      margin-bottom: $small-spacing;
      //margin-right: -0.33rem;
    }
  }

  .product_info--buycta--store{
    display: block;
    @extend %sku-a--alt;
    @extend %navigation;

    font-size: rem(12);
    font-weight:500;
    letter-spacing: rem(1);
    margin-top: $small-spacing;
  }
}

.product_info--footer{
  a.top_link{
    div.arrow-icon{
      width: $base-spacing*.75;
      height: $base-spacing*.75;
      margin: 0 auto $small-spacing auto;
    }
    display: block;
    margin:0 auto;
    text-align: center;

    @extend %sku-a;
    @extend %navigation;
    font-size: rem(9);
    font-weight: 500;
  }
}
