$sections-map: (
  unsweetened: (
    bgcolor:       #f3fefd,
    text-color:    $unsweetened,
    arrow-color:   $unsweetened,
    arrow-hover:   $gold,
    // bgcolor-lighter: lighten($unsweetened, 25%),
    // gradient-angle: 124deg,
    stripe-1:      lighten($unsweetened, 2%),
    stripe-2:      lighten($unsweetened, 3%),
    stripe-3:      lighten($unsweetened, 2%),
    stripe-4:      lighten($unsweetened, 4%),
    stripe-5:      lighten($unsweetened, 2%),
  ),
  slightly-sweet: (
    bgcolor:       $white,
    text-color:    $slightlysweet,
    arrow-color:   $slightlysweet,
    arrow-hover:   $gold,
    // bgcolor-lighter: lighten($slightlysweet, 12%),
    // gradient-angle: 90deg,
    stripe-1:      lighten($slightlysweet, 2%),
    stripe-2:      lighten($slightlysweet, 3%),
    stripe-3:      lighten($slightlysweet, 2%),
    stripe-4:      lighten($slightlysweet, 4%),
    stripe-5:      lighten($slightlysweet, 2%),
  ),
  cold-brew: (
    bgcolor:       $richblack,
    bgcolor-alt:   #444,

    text-color:    $white,
    arrow-color:   $white,
    arrow-hover:   $gold,
    // bgcolor-lighter:    $richblack,
    // gradient-angle: 0deg,
    stripe-1:      lighten($richblack, 1%),
    stripe-2:      lighten($richblack, 2%),
    stripe-3:      lighten($richblack, 1.5%),
    stripe-4:      lighten($richblack, 2.5%),
    stripe-5:      lighten($richblack, 1%),
  ),
  latte: (
    bgcolor:       linear-gradient(0deg, #2c4815, #74a454),
    bgcolor-alt:   #2c4815,
    text-color:    $white,
    arrow-color:   #74a454,
    arrow-hover:   $gold,
    // bgcolor-lighter:    #74a454,
    // gradient-angle: 0deg,
    stripe-1:      lighten(#2c4815, 0%),
    stripe-2:      lighten(#2c4815, 1%),
    stripe-3:      lighten(#2c4815, 2%),
    stripe-4:      lighten(#2c4815, 3%),
    stripe-5:      lighten(#2c4815, 1%),
  )
);
