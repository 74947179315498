$section-base-padding: $base-spacing*6;
$max-bottle-width: rem(400);

section.product_info {
  position: relative;
  padding: $base-spacing*3 0 $base-spacing*2 0;

  @include media($tablet-portrait) {
    padding-top: $base-spacing*3;
    padding-bottom: $section-base-padding - $base-spacing*3;
  }
  @include media($tablet-landscape) {
    padding-top: $base-spacing*1;
    padding-bottom: $section-base-padding - $base-spacing*2;
  }
  @include media($desktop) {
    padding-top: $base-spacing*2;
    padding-bottom: $section-base-padding - $base-spacing;
  }

  footer.product_info--footer{
    position: relative;
    padding-top: $base-spacing*3;

    @include media($tablet-landscape) {  padding-top: $base-spacing*6; }
  }
}

section.product_info{
  .product_info--container {
    position: relative;
    @include container-small;

    // @include media($tablet-landscape) {
    //   padding-right: $base-spacing*2;
    // }

  }
  .product_info--wrap { height: 100%;}
  .product_info--columns {
    position: relative;
    width: 100%;
    height: 100%;
    @include clearfix;

    .product_info--column--left{
      width: 100%;
      float: left;

      @include media($tablet-portrait) {
        max-width: 55%;
      }
      @include media($tablet-landscape) {
        padding-left: $base-spacing*7;
        //margin-top: $base-spacing*2.5;
        max-width: 65%;
      }

      .product-subpages-wrap{
        margin-bottom: $base-spacing*1.5;
        @include media($tablet-landscape) { margin-top: $section-base-padding - $base-spacing*2.5; }

        .product-subpage{
          display: none;

          &.js-active{
            display: block;
          }
        }
      }

    }

    .product_info--column--right{
      overflow: visible;
      float: right;
      margin-top: 0;

      width: 100%;

      @include media($tablet-portrait) {
        max-width: 42%;
        float: right;
        position: relative;
      }

      @include media($tablet-landscape) {
        max-width: 35%;
      }

      .product_info--images{
        @include clearfix;
        padding: $base-spacing 0;

        //background-color: pink;
        margin-bottom: $base-spacing*1.5;

        @include media($tablet-portrait) {
          float: right;
          margin-top: $section-base-padding - $base-spacing*4;
          margin-bottom: $base-spacing*-.5;
          border-width: 0;
        }
        .product_info--images--wrap{
          // background-color: cyan;
          width: 49%;
          max-width: $max-bottle-width;

          display: inline-block;
          float: left;
          @include media($tablet-portrait) { margin-bottom: $base-spacing*-1; }
          @include media($tablet-landscape) { margin-bottom: $base-spacing*-2; }


          & > img {
            height: auto;
            width: auto;
            &.product_info--images--bottleimage{}
          }

          @include media($tablet-portrait) {
            width: auto;
            display: none;
            float: none;
            &.js-active{ display: block; }
          }

          span.label {
            margin-top: $base-spacing*-1;
            @include media($tablet-portrait) {
              display: none;
              margin-top: 0;

            }
          }
        }
        .product_info--certs{
          margin-top: $base-spacing;
          display: block;
          //height: $base-spacing*2.25;

          .product_info--certs--organic,
          .product_info--certs--non_gmo{
            //background-color: pink;
            display: inline-block;
            height: $base-spacing*2.25;
            width: 30%;
          }
          .product_info--certs--organic{text-align: right;}
          .product_info--certs--non_gmo{text-align: left;}

          @include media($tablet-portrait) {
            position: absolute;
            bottom: 8%; right: 10%;
            height: auto;
            z-index: 100;

            .product_info--certs--organic,
            .product_info--certs--non_gmo{
              width: 100%; height: 100%;
              display: block;

              text-align: center;
            }

          }
          @include media($tablet-landscape) { bottom: 5%;  right:7%;}

        }

      }

      .product_info--sizes {
        width: 100%;
        max-width: $max-bottle-width;
        float: right;
      }
    }
  }
}
