section.separator{
  position: relative;

  padding-top: $base-spacing*3;
  padding-bottom: $base-spacing*3;

  @include media($tablet-portrait) {
    padding-top: $base-spacing*4;
    padding-bottom: $base-spacing*4;
  }
  @include media($tablet-landscape) {
    padding-top: $base-spacing*6;
    padding-bottom: $base-spacing*6;
  }
  @include media($desktop) {}
  @include media($big-desktop) {}

  .separator--icon{
    position: relative;
    z-index: 1;

    width: $base-spacing*8;
    background-color: $white;
    padding: 0 $base-spacing;

    //width: rem(196);
    margin: 0 auto;

    svg{
      circle,
      path { fill: $light-gray; }
    }
  }


  hr{
    position: absolute;
    z-index: 0;
    bottom: 45%; left: 0; right: 0;
  }
}
