$base-z-index: 0;

html { z-index: 100; }
body { z-index: 200; }
main { z-index: 300; }

main.page-content { z-index: 500; }
div.product_info--background { z-index: 500;}
article.product_info--container { z-index: 510;}

nav.subnav-dots,div.subnav-product { z-index: 600;}


div.nav--desktop__menu__curtain { z-index: 800; }
header.nav--mobile-header{ z-index: 800; }
nav.nav--mobile-tabbar{  z-index: 900; }
nav.nav--desktop{ z-index: 900; }

div.modal { z-index: 950; }

div.nav--mobile-tabbar.secondary-list { z-index: 1000;}
div.nav--mobile-tabbar.secondary-list .nav--mobile-tabbar__menu { z-index: 1005;}





//div.preload-overlay.init { z-index: 1100; }
div.preload-overlay { z-index: 850; }
