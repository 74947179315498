article.legal{
  header.page--header .page--header__content{
    @include container-small;
    span { @extend h3; }
  }

  section{ @include container-small; }

  @include clearfix;

  @include media($phone) {
    p, ul, ul li {font-size: 0.9rem;}
  }

  header{
    margin-bottom: $base-spacing;
  }
  ul{
    margin-top: $base-spacing * -0.5;
    margin-bottom: $base-spacing;
  }
  ul li{
    list-style: initial;
    list-style-position: inside;
  }
}
