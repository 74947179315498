section.product_info.latte{
  .product_info--background{
    top: 23%; bottom: 35%;
    svg path#stripe{ fill: #f9f8e7; }
  }
}

section.subbrand_hero.latte#latte--page-hero{
  background: none;
  
  div.subbrand_hero--background{
    height: 120%;

    @include media($tablet-portrait) { height: 100%;}
  }

  .subbrand_hero--content--latte_stripe{
    position: absolute;
    bottom: -2px;

    height: 28%;
    @include media($tablet-portrait) { height: 35%;}
    z-index: 504;

    width: 150%;
    left: -25%; right:-25%;

    svg{
      position: absolute;
      top:0;
      height: 100%;
      width: 100%;

    }

    @include media($tablet-portrait) {
      width: 120%;
      left: -10%; right:0;
    }
    @include media($tablet-landscape) {
      width: 100%;
      left: 0; right:0;
    }
    @include media($desktop) {}
    @include media($big-desktop) {}
  }

  div.subbrand_hero--content{
    padding: $base-spacing*4 0 $base-spacing*2 0;

    @include media($tablet-portrait) {
      padding-bottom: $base-spacing*3;
    }

    span.subbrand_hero--headline{
      margin: 0 auto;
      width: 100%;
      max-width: rem(500);
      padding: 0 $base-spacing*2;

      @include media($tablet-portrait) {}
      @include media($tablet-landscape) {}
      @include media($desktop) {}
      @include media($big-desktop) {}
    }

    .subbrand_hero--image-wrap{
      //display: none;
      padding-top: $base-spacing;
      text-align: center;
      margin-left: auto;
      margin-right: auto;


      @include media($tablet-portrait) {
        display: block;
        height:100%;
        width: 90%;
        max-width: rem(700);
      }
      @include media($tablet-landscape) {}
      @include media($desktop) {}
      @include media($big-desktop) {

      }
    }

    .subbrand_hero--romance{ display: none;}
  }
}
