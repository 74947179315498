ul,
ol {
  margin: 0;
  padding: 0;
}

ul { list-style-type: none; }

ol{
  list-style-type: decimal;
  list-style-position: inside;
  //margin-left: $base-spacing;
}


dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}
