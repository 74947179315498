$nav-height: $base-spacing * 3;
$nav-padding: $base-spacing;
$drawer-open-speed: 300ms;

%active-submenu-link{
  color: $medium-gray;
  position:relative;
  padding-left: .8rem;

  &:before{
    content:"";

    position: absolute;
    left:rem(-2); top: rem(2);

    display: block;
    float: left;
    width: 0.7rem;
    height: 0.7rem;
    background-size: 0.7rem;
    background: url("/img/icons/right-arrow.svg");
    background-repeat: none;
  }
}

header.nav--desktop-header { display: none;}

header.nav--desktop-header nav.nav--desktop{
  position: fixed;
  left: 0; right: 0;
  top: 0;

  display: block;
  text-align: center;
  border-bottom: $secondary-border;

  transition-property: border-color;
  transition-duration: $base-duration;
  transition-timing-function: $base-timing;
}

header.nav--desktop-header nav.nav--desktop .page__logo-main{
  @include container;
  position: relative;

  z-index: 100;
  a {
    display: block;

    margin-top: $nav-padding;
    width: $base-spacing * 5;
    transition-property: margin-top, width;
    transition-duration: $base-duration;
    transition-timing-function: $base-timing;

    position: absolute;
    left: $base-spacing;
    top:0;

  }
  svg {
    width: 100%;
    height: 100%;
    g > * { transition: fill $base-duration*.75 $base-timing; }
    g #TeasTea > *,
    g #Organic > *,
    g #BoxBorder > * { fill: $base-font-color; }
    g #BG {fill: $white;}
  }
  &:hover svg{
    g #TeasTea > *,
    g #Organic > * { fill: $gold; }
    g #BoxBorder > * { fill: $gold; }
  }
}

header.nav--desktop-header nav.nav--desktop.alt-layout{
  .page__logo-main{
    a{
      width: $base-spacing * 8;
      margin-top: $nav-padding*2.5;
    }
  }

  &:not(.js-active){
    border-color: transparent;
    .nav--desktop__menu.main-level{ background-color: transparent; }
  }
}

header.nav--desktop-header nav.nav--desktop div.nav--desktop__menu {
  width: 100%;
  text-align: right;

  ul{
    //@include container;
    display: inline-block;
    //font-size: 0;
    a, span{
      color: $black;
      cursor: pointer;

      &:hover,
      &:active,
      &.js-active:hover {
        color: $gold;

        li svg path,
        li svg circle,
        li svg polygon{ fill: $gold; }
      }

      &.js-active,
      &:focus{
        color: $black;

        li svg path,
        li svg circle,
        li svg polygon{ fill: $black; }
      }
    }
  }

  // a, span{
  //   color: $black;
  //   cursor: pointer;
  //
  //   &:hover,
  //   &:active,
  //   &.js-active:hover {
  //     color: $gold;
  //
  //     li svg path,
  //     li svg circle,
  //     li svg polygon{ fill: $gold; }
  //   }
  //
  //   &.js-active,
  //   &:focus{
  //     color: $black;
  //
  //     li svg path,
  //     li svg circle,
  //     li svg polygon{ fill: $black; }
  //   }
  // }
}

header.nav--desktop-header nav.nav--desktop div.nav--desktop__menu.main-level{
  .main-level__wrapper{ @include container;}
  font-size: rem(12);

  background-color: $white;
  transition-property: background-color;
  transition-duration: $base-duration;
  transition-timing-function: $base-timing;

  padding-top: $nav-padding*1.5;

  ul{
    span { height: $nav-height; }
    a{
      margin-left: $nav-padding * 2;
      &:last-of-type{ margin-left: $nav-padding * 1.5; }
    }
    a, span{
      @extend %navigation;
      font-weight: 700;

      display: inline-block;
      vertical-align: bottom;
      padding-top: $nav-padding;

      li{
        height: $nav-padding;
        line-height: $nav-padding;
      }
    }
    a:focus,
    span.nav--desktop__menu__submenu-trigger:focus{
      //outline: none;
      color: $gold;
      user-select: none;

      svg polygon { fill: $gold; }
    }
    a:after,
    a.js-active:after{
      display: block;
      margin: 0 auto;
      content: "";
      height: $base-border-thickness;
      width: 0%;
      margin-top: $nav-padding - $base-border-thickness;

      transition: width $base-duration $base-timing;
    }

    a:after{ background-color: $gold; }
    a.js-active:after{ background-color: $black; }

    a.js-active:after,
    a:active:after,
    a:focus:after,
    a:hover:after{
      width: 100%;
    }

    span.nav--desktop__menu__submenu-trigger{
      overflow: hidden;
      position: relative;
      z-index: 10;

      &:hover{ color: $gold; }

      li {
        display: block;
        position: relative;

        margin: 0 auto;
        height: $nav-padding;

        text-align: center;

        line-height: $nav-padding;

        overflow: visible;
        z-index: 30;

        svg {
          transition: transform $base-timing $base-duration;
          polygon { fill: $black; }
        }
      }
      &:before{
        content: " ";
        display: block;
        background-color: $offwhite;
        height: 0 ;
        //height: $nav-height - $small-spacing;
        transition: height $base-timing $drawer-open-speed;
        width: 100%;

        position: absolute;
        bottom: rem(-3);
        z-index: 20;

        border-radius: rem(3);
      }
      &.js-active:before{
        height: $nav-height - $small-spacing*1;
      }
    }

    span.nav--desktop__menu__submenu-trigger#products-submenu-trigger{
      width: $small-spacing*3;
      outline: none;

      li{
        text-align: center;
        width: $nav-padding;
      }

      &:hover li svg path,
      &:focus li svg path {fill: $gold;}

      svg{
        width: $small-spacing;
        height: $small-spacing;
        g path {fill: $black;}
      }

      &.js-active li svg{
        transform: rotate(180deg)
      }
    }

    span.nav--desktop__menu__submenu-trigger#extras-submenu-trigger{
      outline: none;
      margin-left: $nav-padding*1.5;
      border-left: $secondary-border;

      li{ width: $nav-padding*2.5; }

      svg{
        width: $base-spacing;
        height: $base-spacing;
        g path { fill: $black; }
      }

      &.js-active svg g path { fill: $action-color; }
    }
  }

  ul.nav--menu--social{
    display: inline-block;
    margin-left: $nav-padding*2;

    & a.nav--menu--socialicon:first-of-type{ margin-left: 0; }
    & a.nav--menu--socialicon:nth-of-type(n+4){ display: none; }
    & a.nav--menu--socialicon{
      margin-left: $small-spacing;

      li svg path,
      li svg circle,
      li svg polygon{ fill: $black; }

      &:hover,
      &:active,
      &.js-active:hover {
        color: $gold;

        li svg path,
        li svg circle,
        li svg polygon{ fill: $gold; }
      }

      &.js-active,
      &:focus{
        color: $black;

        li svg path,
        li svg circle,
        li svg polygon{ fill: $gold; }
      }
    }

    & li{
      display: inline-block;
      height: $nav-padding;
      width: $nav-padding;
    }
  }

  .nav--desktop__menu__link--shopnow{
    float: right;
    margin-left: $nav-padding*1.75;
    margin-top: $nav-padding*.55;

    button {
      @extend button.invert;
      font-size: rem(12);

    }
  }
}

header.nav--desktop-header nav.nav--desktop div.nav--desktop__menu.second-level{
  letter-spacing: em(1);
  background-color: $offwhite;
  // @include paper-background();

  overflow: hidden;
  position: relative;

  .nav--desktop__menu__drawer{
    position: relative;
    @include container;

    height: 0;
    transition: height $base-timing $drawer-open-speed;

    &.js-active{ height: $small-spacing * 26; }
  }

  .nav--desktop__menu__drawer__columns{
    padding-top: $nav-padding*1.5;
    padding-right: $base-spacing;

    //background-color: pink;
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav--desktop__menu__drawer__column{
    display: inline-block;
    vertical-align: top;

    &:nth-of-type(2),
    &:nth-of-type(3){
      padding-left: $nav-padding*1.5;
    }
  }

  & .products-submenu{
    text-align: left;

    a.products-submenu__head{
      @extend %navigation;
      color: $black;
      font-size: rem(12);
      font-weight: 700;
      display:block;
      height: $base-spacing*1;
      border-bottom: $secondary-border;
      margin-bottom: $small-spacing;

      &.js-active span{ @extend %active-submenu-link; }
      &:hover span,
      &.js-active:hover span{ color: $action-color; }

    }

    ul.products-submenu__list{
      font-weight: 400;
      font-size: rem(12);
      a{
        display:block;
        height: $base-spacing;

        &.js-active li{ @extend %active-submenu-link; }
        &.js-active:hover li{ color: $action-color; }

        &:last-of-type{ height: $base-spacing*2 }
      }
    }

    // a.js-active,
    // a.js-active span{
    //   color: $action-color;
    // }
  }
}

header.nav--desktop-header div.nav--desktop__menu__curtain{
  @extend .overlay;
  background-color: rgba(220,220,220,0.4);
  display: block;
  //transition: none;
}
