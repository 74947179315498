//https://github.com/modularscale/modularscale-sass

@import "modular-scale/ratios";

$ms-base:  1rem      !default;
$ms-ratio: $golden  !default;
$ms-range: null     !default;
$ms-fluid: true     !default;


@import "modular-scale/tests";

@import "modular-scale/pow";
@import "modular-scale/calc";
@import "modular-scale/generate-list";
@import "modular-scale/sort-list";
@import "modular-scale/round-px";
@import "modular-scale/function";
@import "modular-scale/function-list";

@import "modular-scale/respond";
