$flavors-map: (

  //Unsweetened
  green-tea: (
    label-text:           rgb( 100 , 167 , 11  ),
    text:                 rgb( 55  , 135 , 0   ),
    link:            tint(rgb( 55  , 135 , 0   ), 20%),
    link-hover:     shade(rgb( 55  , 135 , 0   ), 20%),
    inverted-color: $white,
    bgcolor:              rgb( 194 , 211 , 48  ),
    bgcolor-low:  darken( rgb( 194 , 211 , 48  ), 1.5%),
    bgcolor-top:  lighten(rgb( 194 , 211 , 48  ), 3%),
    bgcolor-mid:          rgb( 194 , 211 , 48  )
  ),
  black-tea: (
    label-text:          rgb( 164 , 52  , 58  ) ,
    text:                rgb( 89  , 15  , 22  ),
    link:        lighten(rgb( 89  , 15  , 22  ), 15%),
    link-hover:    shade(rgb( 89  , 15  , 22  ), 50%),
    inverted-color: $white,
    bgcolor:             rgb( 216 , 64  , 73  ),
    bgcolor-low: darken( rgb( 216 , 64  , 73  ), 2%),
    bgcolor-top: lighten(rgb( 216 , 64  , 73  ), 2%),
    bgcolor-mid:         rgb( 216 , 64  , 73  )
  ),
  goldenoolong-tea: (
    label-text:          rgb( 205 , 121 , 37  ) ,
    text:                rgb( 179 , 83  , 6   ),
    link:           tint(rgb( 179 , 83  , 6   ), 20%),
    link-hover:    shade(rgb( 179 , 83  , 6   ), 20%),
    inverted-color: $white,
    bgcolor:             rgb( 234 , 186 , 18  ),
    bgcolor-low:  darken(rgb( 234 , 186 , 18  ), 1%),
    bgcolor-top: lighten(rgb( 234 , 186 , 18  ), 2%),
    bgcolor-mid:         rgb( 234 , 186 , 18  )
  ),
  greenwhite-tea: (
    label-text:          rgb( 0   , 163 , 224 ) ,
    text:                rgb( 6   , 153 , 213 ),
    link:           tint(rgb( 6   , 153 , 213 ), 20%),
    link-hover:    shade(rgb( 6   , 153 , 213 ), 20%),
    inverted-color: #8f8f8f,//$black,
    // bgcolor: rgb( 156 , 200 , 229 )
    bgcolor:             rgb( 212 , 239 , 244 ),
    bgcolor-low: darken( rgb( 212 , 239 , 244  ), 2%),
    bgcolor-top: lighten(rgb( 212 , 239 , 244  ), 2%),
    bgcolor-mid:         rgb( 212 , 239 , 244 )
  ),
  jasminegreen-tea: (
    label-text:          rgb( 41  , 143 , 194 ) ,
    text:                rgb( 39   , 95 , 150 ),
    link:           tint(rgb( 39   , 95 , 150 ), 15%),
    link-hover:    shade(rgb( 39   , 95 , 150 ), 20%),
    inverted-color: $white,
    // bgcolor: rgb( 156 , 200 , 229 )
    bgcolor:             rgb( 128 , 186 , 223 ),
    bgcolor-low: darken( rgb( 128 , 186 , 223 ), 2%),
    bgcolor-top: lighten(rgb( 128 , 186 , 223 ), 2%),
    bgcolor-mid:         rgb( 128 , 186 , 223 )
  ),
  lemongrassgreen-tea: (
    label-text:          rgb( 168 , 173 , 0   ) ,
    text:                rgb( 126 , 130 , 0   ),
    link:           tint(rgb( 126 , 130 , 0   ), 15%),
    link-hover:    shade(rgb( 126 , 130 , 0   ), 25%),
    inverted-color: $white,
    bgcolor:             rgb( 222 , 211 , 58  ),
    bgcolor-low: darken( rgb( 222 , 211 , 58  ), 5%),
    bgcolor-top: lighten(rgb( 222 , 211 , 58  ), 5%),
    bgcolor-mid:         rgb( 222 , 211 , 58  )
  ),
  mintgreen-tea: (
    label-text:          rgb( 0   , 171 , 142 ) ,
    text:                rgb( 4   , 117 , 98  ),
    link:           tint(rgb( 4   , 117 , 98  ), 20%),
    link-hover:    shade(rgb( 4   , 117 , 98  ), 20%),
    inverted-color: $white,
    bgcolor:             rgb( 142 , 219 , 211 ),
    bgcolor-low: darken( rgb( 142 , 219 , 211 ), 3%),
    bgcolor-top: lighten(rgb( 142 , 219 , 211 ), 3%),
    bgcolor-mid:         rgb( 142 , 219 , 211 )
  ),
  rosegreen-tea: (
    label-text:          rgb( 198 , 87  , 154 ) ,
    text:                rgb( 200 , 31  , 135 ),
    link:           tint(rgb( 200 , 31  , 135 ), 15%),
    link-hover:    shade(rgb( 200 , 31  , 135 ), 20%),
    inverted-color: $white,
    bgcolor:             rgb( 241 , 138 , 172 ),
    bgcolor-low: darken( rgb( 241 , 138 , 172  ), 1.5%),
    bgcolor-top: lighten(rgb( 241 , 138 , 172  ), 1.5%),
    bgcolor-mid:         rgb( 241 , 138 , 172 )
  ),
  chamomile-tea: (
    label-text:          rgb( 209 , 144 , 0   ) ,
    text:                rgb( 186 , 111 , 1   ) ,
    link:           tint(rgb( 186 , 111 , 1   ), 15%),
    link-hover:    shade(rgb( 186 , 111 , 1   ), 20%),
    inverted-color: $white,
    // bgcolor: rgb( 243 , 236 , 126 )
    bgcolor:             rgb( 236 , 222 , 87  ),
    bgcolor-low: darken( rgb( 236 , 222 , 87  ), 6%),
    bgcolor-top: lighten(rgb( 236 , 222 , 87  ), 4%),
    bgcolor-mid:         rgb( 236 , 222 , 87  )
  ),

  //Slightly Sweet
  peachginger-tea: (
    label-text:           rgb( 227 , 82  , 5   ) ,
    text:                 rgb( 233 , 39  , 1   ),
    link:            tint(rgb( 233 , 39  , 1   ), 15%),
    link-hover:     shade(rgb( 233 , 39  , 1   ), 25%),
    inverted-color: $white,
    // bgcolor: rgb( 248 , 138 , 18  )
    bgcolor:             rgb( 250 , 181 , 75  ),
    bgcolor-low: darken( rgb( 250 , 181 , 75  ), 3%),
    bgcolor-top: lighten(rgb( 250 , 181 , 75  ), 3%),
    bgcolor-mid:         rgb( 250 , 181 , 75  )
  ),
  pomegranateblueberry-tea:  (
    label-text:          rgb( 160 , 94  , 181 ) ,
    text:                rgb( 157 , 46  , 173 ),
    link:           tint(rgb( 157 , 46  , 173 ), 15%),
    link-hover:    shade(rgb( 157 , 46  , 173 ), 20%),
    inverted-color: $white,
    // bgcolor: rgb( 195 , 156 , 210 )
    bgcolor:             rgb( 199 , 169 , 217 ),
    bgcolor-low: darken( rgb( 199 , 169 , 217 ), 2%),
    bgcolor-top: lighten(rgb( 199 , 169 , 217 ), 2%),
    bgcolor-mid:         rgb( 199 , 169 , 217 )
  ),
  lemonmint-tea: (
    label-text:          rgb( 120 , 190 , 32  ) ,
    text:                rgb( 74  , 179 , 0   ),
    link:           tint(rgb( 74  , 179 , 0   ), 20%),
    link-hover:    shade(rgb( 74  , 179 , 0   ), 25%),
    inverted-color: #8f8f8f,//$black,
    // bgcolor: rgb( 234 , 234 , 85  )
    bgcolor:             rgb( 234 , 231 , 89  ),
    bgcolor-low: darken( rgb( 234 , 231 , 89  ), 7%),
    bgcolor-top: lighten(rgb( 234 , 231 , 89  ), 5%),
    bgcolor-mid:         rgb( 234 , 231 , 89  )
  ),
  hibiscus-tea: (
    label-text:          rgb( 224 , 60  , 49  ) ,
    text:                rgb( 229 , 0   , 4   ),
    link:           tint(rgb( 229 , 0   , 4   ), 15%),
    link-hover:    shade(rgb( 229 , 0   , 4   ), 20%),
    inverted-color: $white,
    bgcolor:             rgb( 247 , 154 , 136 ),
    bgcolor-low: darken( rgb( 247 , 154 , 136 ), 2%),
    bgcolor-top:  lighten(rgb( 247 , 154 , 136 ), 2%),
    bgcolor-mid:         rgb( 247 , 154 , 136 )
  ),
  fujiapple-tea: (
    label-text:          rgb( 200 , 16  , 46  ) ,
    text:                rgb( 205 , 0   , 1  ),
    link:           tint(rgb( 205 , 0   , 1  ), 15%),
    link-hover:    shade(rgb( 205 , 0   , 1  ), 20%),
    inverted-color: $white,
    // bgcolor: rgb( 238 , 55  , 34  )
    bgcolor:             rgb( 242 , 114 , 75 ),
    bgcolor-low: darken( rgb( 242 , 114 , 75 ), 2%),
    bgcolor-top: lighten(rgb( 242 , 114 , 75 ), 2%),
    bgcolor-mid:         rgb( 242 , 114 , 75 )
  ),
  mangoyuzu-tea: (
    label-text:          rgb( 232 , 119 , 34  ) ,
    text:                rgb( 231 , 89  , 0   ),
    link:           tint(rgb( 231 , 89  , 0   ), 15%),
    link-hover:    shade(rgb( 231 , 89  , 0   ), 20%),
    inverted-color: $white,
    bgcolor:             rgb( 249 , 204 , 17  ),
    bgcolor-low: darken( rgb( 249 , 204 , 17  ), 3%),
    bgcolor-top: lighten(rgb( 249 , 204 , 17  ), 5%),
    bgcolor-mid:         rgb( 249 , 204 , 17  )
  ),

  //Coldbrew
  cb_green-tea: (
    label-text:          rgb( 100 , 167 , 11  ) ,
    text:                rgb( 194 , 211 , 48  ),
    link:                $gold,
    link-hover:          $white,
    inverted-color:      $white,
    bgcolor:             rgb( 31  , 31  , 31  ),
    bgcolor-low: darken( rgb( 31  , 31  , 31  ), 2%),
    bgcolor-top: lighten(rgb( 31  , 31  , 31  ), 2%),
    bgcolor-mid:         rgb( 31  , 31  , 31  )
  ),
  cb_raspberry-tea: (
    label-text:          rgb( 244 , 59  , 114 ),
    text:                rgb( 244 , 59  , 114 ),
    link:                $gold,
    link-hover:          $white,
    inverted-color:      $white,
    bgcolor:             rgb( 31  , 31  , 31  ),
    bgcolor-low: darken( rgb( 31  , 31  , 31  ), 2%),
    bgcolor-top: lighten(rgb( 31  , 31  , 31  ), 2%),
    bgcolor-mid:         rgb( 31  , 31  , 31  )
  ),
  cb_mandarin-tea: (
    label-text:          rgb( 252 , 175  , 16 ),
    text:                rgb( 252 , 175  , 16 ),
    link:                $gold,
    link-hover:          $white,
    inverted-color:      $white,
    bgcolor:             rgb( 31  , 31  , 31  ),
    bgcolor-low: darken( rgb( 31  , 31  , 31  ), 2%),
    bgcolor-top: lighten(rgb( 31  , 31  , 31  ), 2%),
    bgcolor-mid:         rgb( 31  , 31  , 31  )
  ),

  //Lattes
  matchalatte-tea: (
    label-text:          rgb( 91  , 141 , 61 ),
    text:                rgb( 91  , 141 , 61 ),
    link:           tint(rgb( 91  , 141 , 61 ), 20%),
    link-hover:    shade(rgb( 91  , 141 , 61 ), 40%),
    inverted-color: #afa378,
    bgcolor:             rgb( 245 , 244 , 223  ),
    bgcolor-low: darken( rgb( 138 , 181 , 0  ), 2%),
    bgcolor-top: lighten(rgb( 138 , 181 , 0  ), 2%),
    bgcolor-mid:         rgb( 138 , 181 , 0  )
  ),
  blacklatte-tea: (
    label-text:          rgb( 139 , 45  , 33  ),
    text:                rgb( 139 , 45  , 33  ),
    link:           tint(rgb( 139 , 45  , 33  ), 20%),
    link-hover:    shade(rgb( 139 , 45  , 33  ), 40%),

    inverted-color: #afa378,
    bgcolor:             rgb( 245 , 244 , 223  ),
    bgcolor-low: darken( rgb( 177 , 54  , 32  ), 2%),
    bgcolor-top: lighten(rgb( 177 , 54  , 32  ), 2%),
    bgcolor-mid:         rgb( 177 , 54  , 32  )
  ),
  chailatte-tea: (
    label-text:          rgb( 106 , 53  , 126 ),
    text:                rgb( 106 , 53  , 126 ),
    link:           tint(rgb( 106 , 53  , 126 ), 20%),
    link-hover:    shade(rgb( 106 , 53  , 126 ), 40%),
    inverted-color: #afa378,
    bgcolor:             rgb( 245 , 244 , 223  ),
    bgcolor-low: darken( rgb( 137 , 72  , 157 ), 2%),
    bgcolor-top: lighten(rgb( 137 , 72  , 157 ), 2%),
    bgcolor-mid:         rgb( 137 , 72  , 157 )
  ),

  //Single-serve Cups
  kcups-tea: (
    label-text:          rgb( 100 , 167 , 11  ) ,
    text:                rgb( 100 , 167 , 11  ) ,
    link:            tint(rgb( 55  , 135 , 0   ), 10%),
    link-hover:     shade(rgb( 55  , 135 , 0   ), 10%),
    inverted-color: $white,
    bgcolor:             rgb( 194 , 211 , 48  ),
    bgcolor-low: darken( rgb( 194 , 211 , 48  ), 2%),
    bgcolor-top:  lighten(rgb( 194 , 211 , 48  ), 2%),
    bgcolor-mid:         rgb( 194 , 211 , 48  )
  )
);
