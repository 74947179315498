a.skip-link {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;

  color: red;
}

a.skip-link:focus {
  position:absolute;
  left: 0;
  width:auto;
  height:auto;
  padding: $small-spacing;

  z-index: 1100;

  color: white;
  background-color: $gold;
}
