.preload-overlay {
	@extend .overlay;

	background-color: $white;
	transition: visibility 200ms, opacity 200ms $base-timing;

	.preload-overlay__loader{
		//@include vertical-align(absolute);
		//margin: auto;
		@include XYcenter();

		width: 5rem;
		height: 5rem;

		// svg {
		// 	#tt, #organic{ //fill: red; }
		// 	polyline#box{
		// 		//stroke: rgb(90, 90, 90);
		// 		stroke-dasharray: 0, 400%;
		// 		stroke-dashoffset: 0;
		//
		// 		//stroke-offset: 9.5rem * .5;
		// 	  animation: dash 2s ease normal infinite;
		// 	}
		//
		// 	@keyframes dash {
		// 		0% {
		// 			stroke-dasharray: 0, 400%;
		// 			stroke-dashoffset: 0;
		// 		}
		// 		50% {
		// 		  stroke-dasharray: 400%, 400%;
		// 			stroke-dashoffset: 0;
		// 		}
		// 		51% {
		// 			stroke-dashoffset: 0;
		// 		}
		//
		// 		100% {
		// 			stroke-dashoffset: -400%;
		// 			stroke-dasharray: 400%, 400%;
		// 		}
		// 	}
		//
		// }
	}

}
