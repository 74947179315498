.modal{
  background-color: rgba(255,255,255,0.9);

  display: none;
  @include media($tablet-portrait) { display: block; }

  .modal--content{
    //@include vertical-align(absolute);
		//margin: auto;
		@include XYcenter();

    width: 80%; height: 80%;
    max-width: rem(800);

    & > div {
      padding-top: $base-spacing;
      display: block !important;
    }
  }


  .modal--content__toggle{
    position: absolute;
    top: 0; right:0;
    float: right;

		color: $black;
		text-align: center;
		display: block;

		cursor: pointer;

		transition: background-color $base-duration $base-timing;

		svg{
			width: $base-spacing;
			height: $base-spacing;
			polygon {
				fill: $black;
				transition: fill $base-duration $base-timing;
			}
		}

		&:hover svg,
		&:focus svg{
			polygon {
				fill: $gold;
			}
		}
	}
}
