@each $id, $map in $sections-map {
  section.subbrand_hero##{$id}--page-hero{
    $bg-color: map-get($map, bgcolor);
    $text-color: map-get($map, text-color);
    $arrow-color: map-get($map, arrow-color);
    $arrow-hover: map-get($map, arrow-hover);

    $bg-color-alt: map-get($map, text-color);
    @if (map-get($map, bgcolor-alt)) {
      $bg-color-alt: map-get($map, bgcolor-alt);
    }

    // $light-bg-color: map-get($map, bgcolor-lighter);
    // $angle: map-get($map, gradient-angle);

    div.subbrand_hero--wrap{
      background: $bg-color;
      //background: linear-gradient($angle, $bg-color, $light-bg-color);
      span.subbrand_hero--section-label { color: $text-color; border-color: $text-color; }
      span.subbrand_hero--headline  { color: $text-color }
      p.subbrand_hero--romance  { color: $text-color }

    }

    div.subbrand_hero--background  {
      svg#hero_stripes{
        polygon#s1 { fill: map-get($map, stripe-1); }
        polygon#s2 { fill: map-get($map, stripe-2); }
        polygon#s3 { fill: map-get($map, stripe-3); }
        polygon#s4 { fill: map-get($map, stripe-4); }
        polygon#s5 { fill: map-get($map, stripe-5); }
      }
    }

    a.subbrand_hero--below_fold div.arrow-icon{
      position: relative; z-index: 505;

      svg polygon{
        fill: $arrow-color;
        transition: fill $base-duration $base-timing;
      }
      &:hover, &:focus {
        svg polygon{ fill: $arrow-hover; }
      }
    }

    span.subbrand_hero--submenu-label{ color: $bg-color; }
    div.subbrand_hero--submenu-button{ background: $bg-color-alt; }
  }
}

section.subbrand_hero{
  position: relative;
  @include paper-background();

  div.subbrand_hero--wrap{
    position: relative;
    overflow: hidden;
  }

  div.subbrand_hero--content{
    .subbrand_hero--copy-wrap{
      position: relative;
      z-index: 501;
    }
    overflow: hidden;
    color: $white;

    padding-top: $base-spacing*4;
    padding-bottom: $base-spacing*2;

    @include media($tablet-portrait) {
      padding-top: $base-spacing*7;
      padding-bottom: $base-spacing*7;
    }
    span.subbrand_hero--section-label{
      display: inline-block;
      font-size: 0.8rem;
      @include media($tablet-portrait) { font-size: 1rem; }

      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: rem(3);

      padding-bottom: $small-spacing;
      border-bottom: $base-border;
      margin: 0 auto $base-spacing*2 auto;
    }

    span.subbrand_hero--headline{
      display: block;
      @extend %heading;

        @include ms-respond(font-size, 4);
        margin-left: rem(-4);

        @include media($tablet-portrait) {
          @include ms-respond(font-size, 5);
          margin-left: rem(-6);
        }
        @include media($tablet-landscape) {}
        @include media($desktop) {}
        @include media($big-desktop) { }
    }
    p.subbrand_hero--romance{
      font-size: rem(20);
      font-weight: 300;
      max-width: 20em;

    }
    .subbrand_hero--copy-wrap{
      @include container
    }
  }

  a.subbrand_hero--below_fold{
    display: none;
    @include media($tablet-landscape) { display: block; }

    position: absolute;
    bottom:0; left:0; right:0;
    margin: 0 auto;
    text-align: center;
    width: $base-spacing*4;
    padding-bottom: $base-spacing*1;

    div.arrow-icon{
      width: $base-spacing*1.5;
      margin: 0 auto $small-spacing auto;

      //svg polygon{ fill: $white; }
    }
  }

  div.subbrand_hero--submenu-button{
    //@include clearfix;
    @include media($tablet-landscape) {  display: none; }

    margin: 0 $base-spacing;
    height: $base-spacing*1.75;
    line-height: $base-spacing*1.75;
    color: $white;

    cursor: pointer;

    & .label{
      float: left;
      height: 100%;

      font-size: rem(12);
      letter-spacing: em(1.5);
      font-weight: 500;
      text-transform: uppercase;
      padding: 0 $base-spacing;
    }

    & .icon{
      display: inline-block;
      height: 100%;
      float: right;
      border-left: 1px solid $offwhite;
      padding: 0 $base-spacing*.75;

      & svg{
        height: 100%;
        width: $base-spacing;

        transition: transform $base-duration $base-timing;
        polygon {fill: $white;}
      }
    }

    &.js-active{
      & .icon svg {
        transform: rotate(180deg);
        polygon {fill: $white;}
      }
    }
  }

  div.subbrand_hero--background { @include stripes; }
}
