// Colors
$white: #FFF;
$offwhite: #f5f5f5;
$paper-color: #fbf9f8;

$black: #333;
$richblack: #191919;

$dark-gray:   lighten($black, 0   );
$medium-gray: lighten($black, 40% );
$gray: lighten($black, 55% );
$light-gray:  lighten($black, 70% );


//** Official/PMS colors **//
// $unsweetened:      rgb( 0   , 74  , 118 ) ; //unsweetened color
$unsweetened:      rgb( 4   , 63  , 108 ) ; //unsweetened color

// $slightlysweet:    rgb( 155 , 50  , 89  ) ; //slightly sweet color
$slightlysweet:    rgb( 166 , 24  , 76  ) ; //slightly sweet color

$coldbrew:         rgb( 31  , 31  , 31  ) ; //slightly sweet color


$gold:             rgba( 222 , 180 , 85, 1 ) ; //slightly sweet color
$papertexture:     rgb( 219 , 200 , 182 ) ; //slightly sweet color

$itoen-green:      rgb( 0   , 175 , 65  ) ; //official green
$itoen-website: #cc6601;

// Font Colors
$base-font-color: $dark-gray;
$light-font-color: $medium-gray;

$action-color: $gold;
$warning-color: #de6b55;
$confirm-color: #47d283;


// Background Colors
$base-background-color: $white;
$secondary-background-color: $offwhite;


@mixin paper-background() {
  background-color: $paper-color;
  background-image: url('/img/paper_texture.gif');
  background-repeat: repeat;
  background-position: left top;
}
